import BaseModel            from '@/lib/data/model/BaseModel'
import SettingItemValidator from '@/api/validations/setting/SettingItemValidator'
import SettingItemResource  from '@/api/resources/setting/SettingItemResource'

export default class SettingItemModel extends BaseModel {
  constructor (payload = {}, resource = SettingItemResource, validator = SettingItemValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
}

import ApiSettingGroupEnum from '@/api/enums/ApiSettingGroupEnum'

export default {
  name   : 'settingColors',
  methods: {
    getApiGroupColor (apiGroup) {
      switch (apiGroup) {
      case ApiSettingGroupEnum.API:
        return 'deep-orange'

      case ApiSettingGroupEnum.WEB:
        return 'teal'

      case ApiSettingGroupEnum.ADMIN:
        return 'purple'

      default:
        return 'grey'
      }
    }
  }
}

/* eslint-disable no-misleading-character-class */

import * as typeUtils                       from '@/lib/utils/type'
import { pathToValue, sortObjectDeepByKey } from '@/lib/utils/object'

export const types = {
  accepted: propValue => isNotEmpty('', propValue) && propValue !== 0,

  alpha: propValue => (new RegExp(/^[a-zA-Zα-ωίϊΐόάέύϋΰήώΑ-ΩΊΪΪ́ΌΆΈΎΫΫΉΏ]*$/)).test(propValue),

  alphaNum: propValue => (new RegExp(/^[a-zA-Zα-ωίϊΐόάέύϋΰήώΑ-ΩΊΪΪ́ΌΆΈΎΫΫΉΏ0-9]*$/)).test(propValue),

  numeric: propValue => (new RegExp(/^[0-9]*$/)).test(propValue),

  array: propValue => typeUtils.isArray(propValue),

  object: propValue => typeUtils.isObject(propValue),

  function: propValue => typeUtils.isFunction(propValue),

  promise: propValue => typeUtils.isPromiseLike(propValue),

  boolean: propValue => typeUtils.isBoolean(propValue),

  number: propValue => typeUtils.isNumber(propValue),

  integer: propValue => typeUtils.isNumber(propValue) && Number(propValue) === parseInt(propValue) && Number(propValue) % 1 === 0,

  float: propValue => typeUtils.isNumber(propValue) && Number(propValue) === parseFloat(propValue) && Number(propValue) % 1 !== 0,

  string: propValue => typeUtils.isString(propValue),

  regex: propValue => ![undefined, null].includes(propValue) && propValue.constructor === RegExp,

  url: propValue => (new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$', 'i')).test(propValue),

  hex: propValue => (new RegExp('#[0-9A-F]{6}$', 'i').test(propValue)),

  hexTransparent: propValue => (new RegExp('#[0-9A-F]{6}[0-9a-f]{0,2}$', 'i').test(propValue)),

  domain: propValue => {
    if (typeof propValue !== 'string') return false
    const opts = {}

    const parts = propValue.split('.')
    if (parts.length <= 1) return false

    const tld = parts.pop()
    const tldRegex = /^(?:xn--)?[a-zA-Z0-9]+$/gi

    if (!tldRegex.test(tld)) return false
    if (opts.subdomain === false && parts.length > 1) return false

    return parts.every(function (host, index) {
      if (opts.wildcard && index === 0 && host === '*' && parts.length > 1) return true

      const hostRegex = /^(?!:\/\/)([a-zA-Z0-9]+|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])$/gi

      return hostRegex.test(host)
    })
  },

  /*
    ^(
    (AT)?U[0-9]{8} |                              # Austria
    (BE)?0[0-9]{9} |                              # Belgium
    (BG)?[0-9]{9,10} |                            # Bulgaria
    (CY)?[0-9]{8}L |                              # Cyprus
    (CZ)?[0-9]{8,10} |                            # Czech Republic
    (DE)?[0-9]{9} |                               # Germany
    (DK)?[0-9]{8} |                               # Denmark
    (EE)?[0-9]{9} |                               # Estonia
    (EL|GR)?[0-9]{9} |                            # Greece
    (ES)?[0-9A-Z][0-9]{7}[0-9A-Z] |               # Spain
    (FI)?[0-9]{8} |                               # Finland
    (FR)?[0-9A-Z]{2}[0-9]{9} |                    # France
    (GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3}) | # United Kingdom
    (HU)?[0-9]{8} |                               # Hungary
    (IE)?[0-9]S[0-9]{5}L |                        # Ireland
    (IT)?[0-9]{11} |                              # Italy
    (LT)?([0-9]{9}|[0-9]{12}) |                   # Lithuania
    (LU)?[0-9]{8} |                               # Luxembourg
    (LV)?[0-9]{11} |                              # Latvia
    (MT)?[0-9]{8} |                               # Malta
    (NL)?[0-9]{9}B[0-9]{2} |                      # Netherlands
    (PL)?[0-9]{10} |                              # Poland
    (PT)?[0-9]{9} |                               # Portugal
    (RO)?[0-9]{2,10} |                            # Romania
    (SE)?[0-9]{12} |                              # Sweden
    (SI)?[0-9]{8} |                               # Slovenia
    (SK)?[0-9]{10}                                # Slovakia
    )$
  */
  vat: propValue => /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/.test(String(propValue).toUpperCase()),

  email: propValue => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(propValue),

  date: propValue => typeof propValue.getTime === 'function' && typeof propValue.getMonth === 'function' && typeof propValue.getYear === 'function',

  ip: propValue => types.ipv4(propValue) || types.ipv6(propValue),

  ipv4: propValue => /^(?:\d{1,3}(?:\.|$)){4}/.test(propValue),

  ipv6: propValue => (/^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/.test(propValue)),

  // YYYY-MM-DD HH:mm:ss
  mysqlDateTimeString: propValue => /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01]) (2[0-3]|[01]?[0-9]):([0-5]?[0-9]):([0-5]?[0-9])$/.test(propValue),

  // YYYY-MM-DD or YYYY-MM-DD HH:mm:ss
  mysqlDateOrDateTimeString: propValue => types.mysqlDateString(propValue) || types.mysqlDateTimeString(propValue),

  // YYYY-MM-DD
  mysqlDateString: propValue => /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/.test(propValue),

  // HH:mm:ss
  mysqlTimeString: propValue => /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9]):([0-5]?[0-9])$/.test(propValue),

  // HH:mm
  timeString: propValue => /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/.test(propValue),

  // DD-MM-YYYY
  dateStringDash: propValue => /^(0[1-9]|1\d|2\d|3[01])-(0[1-9]|1[0-2])-(19|20)\d{2}$/.test(propValue),

  // DD/MM/YYYY
  dateStringSlash: propValue => /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/.test(propValue)
}

export const type = (ruleValue, propValue) => typeUtils.isString(ruleValue) ? types[ruleValue](propValue) : propValue instanceof ruleValue

export const required = (ruleValue, propValue) => ruleValue === false ? true : !(propValue === undefined || propValue === null || propValue === '')

export function requiredIf (ruleValue, propValue, dataModel) {
  if (!dataModel) return true
  if (Array.isArray(ruleValue) && ruleValue.length === 2) {
    const [ruleProperty, rulePropertyValue] = ruleValue
    const propertyValue = ruleProperty.includes('.') ? pathToValue(ruleProperty, dataModel) : dataModel[ruleProperty]
    return rulePropertyValue === propertyValue
  } else {
    const propertyValue = ruleValue.includes('.') ? pathToValue(ruleValue, dataModel) : dataModel[ruleValue]
    return propertyValue && dataModel.validator.validateField(ruleValue)
  }
}

export function requiredIfNot (ruleValue, propValue, dataModel) {
  if (!dataModel) return true
  if (Array.isArray(ruleValue) && ruleValue.length === 2) {
    const [ruleProperty, rulePropertyValue] = ruleValue
    const propertyValue = ruleProperty.includes('.') ? pathToValue(ruleProperty, dataModel) : dataModel[ruleProperty]
    return rulePropertyValue !== propertyValue
  } else {
    const propertyValue = ruleValue.includes('.') ? pathToValue(ruleValue, dataModel) : dataModel[ruleValue]
    return !propertyValue || !dataModel.validator.validateField(ruleValue)
  }
}

export const regexp = (ruleValue, propValue) => (new RegExp(ruleValue)).test(propValue) !== false

export const min = (ruleValue, propValue) => typeof propValue !== 'number' ? false : propValue >= ruleValue

export const max = (ruleValue, propValue) => typeof propValue !== 'number' ? false : propValue <= ruleValue

export const between = (ruleValue, propValue) => min(ruleValue[0], propValue) && max(ruleValue[1], propValue)

export const minLen = (ruleValue, propValue) => {
  if (typeUtils.isNumber(propValue)) {
    propValue = String(propValue)
  } else if (!typeUtils.isString(propValue) && !typeUtils.isArray(propValue)) {
    return false
  }
  return propValue.length >= ruleValue
}

export const maxLen = (ruleValue, propValue) => {
  if (typeUtils.isNumber(propValue)) {
    propValue = String(propValue)
  } else if (!typeUtils.isString(propValue) && !typeUtils.isArray(propValue)) {
    return false
  }
  return propValue.length <= ruleValue
}

export const betweenLen = (ruleValue, propValue) => minLen(ruleValue[0], propValue) && maxLen(ruleValue[1], propValue)

export const length = (ruleValue, propValue) => {
  if (typeUtils.isNumber(propValue)) {
    propValue = String(propValue)
  } else if (!typeUtils.isString(propValue) && !typeUtils.isArray(propValue)) {
    return false
  }
  return propValue.length === ruleValue
}

export const equals = (ruleValue, propValue, dataModel) => {
  if (ruleValue.includes('|')) ruleValue = ruleValue.split('|')[0]
  const value2 = pathToValue(ruleValue, dataModel)
  return JSON.stringify(sortObjectDeepByKey(propValue)) === JSON.stringify(sortObjectDeepByKey(value2))
}

export const allValid = (ruleValue, propValue) => ruleValue ? [propValue].every(Boolean) : ![propValue].every(Boolean)
export const anyValid = (ruleValue, propValue) => ruleValue ? [propValue].some(Boolean) : ![propValue].some(Boolean)

export const is = (ruleValue, propValue) => ruleValue === propValue
export const isNot = (ruleValue, propValue) => ruleValue !== propValue
export const isIn = (ruleValue, propValue) => ruleValue.indexOf(propValue) !== -1
export const isNotIn = (ruleValue, propValue) => ruleValue.indexOf(propValue) <= -1
export const isNotEmpty = (ruleValue, propValue) => typeof propValue === 'number' || typeof propValue === 'boolean' || !!propValue

export const dateTimeFormat = (ruleValue, propValue) => {
  if (ruleValue === 'DD/MM/YYYY') return types.dateStringSlash(propValue)
  if (ruleValue === 'DD-MM-YYYY') return types.dateStringDash(propValue)
  if (ruleValue === 'HH:mm') return types.timeString(propValue)
  if (ruleValue === 'HH:mm:ss') return types.mysqlTimeString(propValue)
  if (ruleValue === 'YYYY-MM-DD') return types.mysqlDateString(propValue)
  if (ruleValue === 'YYYY-MM-DD HH:mm:ss') return types.mysqlDateTimeString(propValue)
  if (ruleValue === 'YYYY-MM-DD HH:mm:ss') return types.mysqlDateTimeString(propValue)
  return false
}

export const startsWith = (ruleValue, propValue) => {
  return String(propValue).startsWith(String(ruleValue))
}

export const endsWith = (ruleValue, propValue) => {
  return String(propValue).endsWith(String(ruleValue))
}

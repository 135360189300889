import BaseModel      from '@/lib/data/model/BaseModel'
import TwoFAValidator from '@/api/validations/user/TwoFAValidator'
import TwoFAResource  from '@/api/resources/user/TwoFAResource'
import API            from '@/api/Api'
import { AES }        from '@/lib/crypto/crypto'

export default class TwoFAModel extends BaseModel {
  constructor (payload = {}, resource = TwoFAResource, validator = TwoFAValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */

  async TwoFALogin () {
    return await API.Resource.User.TwoFALogin({
      Pin     : AES.encrypt(this.Pin),
      Token   : this.Token,
      Username: this.Username
    })
  }
}

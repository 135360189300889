<template>
  <div class="pa-1 pa-sm-6">
    <v-img
      class="ma-2 ma-sm-6"
      contain
      height="80"
      position="center"
      src="/img/logo/logo-dark.png"
    />

    <v-card-title
      :class="{'h2': $vuetify.breakpoint.smAndUp, 'h3 font-weight-bold': $vuetify.breakpoint.xs,}"
      class="justify-center mb-5"
    >
      {{ $t('Users.ChangePassword.Title') }}
    </v-card-title>

    <template v-if="successMessage">
      <v-card-subtitle class="h4 text-center my-3">
        {{ successMessage }}
      </v-card-subtitle>

      <v-card-actions class="d-block text-center px-4">
        <v-btn
          :large="$vuetify.breakpoint.smOnly"
          :to="{name: 'Login'}"
          :x-large="$vuetify.breakpoint.mdAndUp"
          class="px-4 px-sm-8 px-md-12"
          depressed
          v-text="$t('Common.Button.Login')"
        />
      </v-card-actions>
    </template>

    <template v-else>
      <v-card-subtitle class="h4 text-left my-3">
        {{ $t('Users.ChangePassword.SubTitle') }}
      </v-card-subtitle>

      <v-card-text>
        <v-form
          ref="form"
          v-model="isFormValid"
          @submit.prevent
        >
          <v-text-field
            v-model="model.CurrentPassword"
            :disabled="isSubmittingData"
            :error-messages="serverErrorMessages['CurrentPassword']"
            :label="$t('Users.ChangePassword.CurrentPassword')"
            :placeholder="$t('Users.ChangePassword.CurrentPassword')"
            :rules="model.validator.vuetifyFormFieldRules('CurrentPassword')"
            clearable
            outlined
            prepend-inner-icon="mdi-lock-outline"
            type="password"
            @focusin="resetServerErrorMessage('CurrentPassword')"
          />

          <div class="my-2" />

          <v-text-field
            v-model="model.Password"
            :disabled="isSubmittingData"
            :error-messages="serverErrorMessages['Password']"
            :label="$t('Users.ChangePassword.NewPassword')"
            :placeholder="$t('Users.ChangePassword.NewPassword')"
            :rules="model.validator.vuetifyFormFieldRules('Password')"
            clearable
            outlined
            prepend-inner-icon="mdi-lock-plus-outline"
            type="password"
            @focusin="resetServerErrorMessage('Password')"
          />

          <div class="my-2" />

          <v-text-field
            v-model="model.ConfirmPassword"
            :disabled="isSubmittingData"
            :error-messages="serverErrorMessages['ConfirmPassword']"
            :label="$t('Users.ChangePassword.ConfirmPassword')"
            :placeholder="$t('Users.ChangePassword.ConfirmPassword')"
            :rules="model.validator.vuetifyFormFieldRules('ConfirmPassword')"
            clearable
            outlined
            prepend-inner-icon="mdi-lock-reset"
            type="password"
            @focusin="resetServerErrorMessage('ConfirmPassword')"
          />
        </v-form>

        <v-alert
          v-if="errorMessage"
          border="left"
          class="text-left"
          color="error"
          dense
          text
          type="error"
        >
          {{ errorMessage }}
        </v-alert>
      </v-card-text>

      <v-card-actions class="d-block text-right px-4">
        <v-btn
          :disabled="isSubmittingData"
          :large="$vuetify.breakpoint.smOnly"
          :to="{name: 'Login'}"
          :x-large="$vuetify.breakpoint.mdAndUp"
          class="px-4 px-sm-8 px-md-12"
          depressed
          plain
          v-text="$t('Common.Button.Cancel')"
        />

        <v-btn
          :disabled="!isFormValid || isSubmittingData"
          :large="$vuetify.breakpoint.smOnly"
          :loading="isSubmittingData"
          :x-large="$vuetify.breakpoint.mdAndUp"
          class="px-4 px-sm-8 px-md-12"
          color="warning"
          depressed
          @click="onSubmitClick"
          v-text="$t('Common.Button.Continue')"
        />
      </v-card-actions>
    </template>
  </div>
</template>

<script>
import i18nRouteMeta       from '@/mixins/i18n/i18nRouteMeta'
import ChangePasswordModel from '@/api/models/user/ChangePasswordModel'
import { toPascalCase }    from '@/lib/utils/string'
import ApiResponseEnum     from '@/api/enums/ApiResponseEnum'

export default {
  name      : 'ChangePassword',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  data () {
    return {
      isFormValid        : false,
      isSubmittingData   : false,
      successMessage     : '',
      errorMessage       : '',
      serverErrorMessages: {},
      componentData      : new ChangePasswordModel()
    }
  },
  computed: {
    model: {
      get () {
        return this.componentData
      },
      set (val) {
        this.componentData = val
      }
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    window.addEventListener('keydown', this.onKeyDown)
    if (this.$refs.form) this.$refs.form.reset()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    window.removeEventListener('keydown', this.onKeyDown)
  },
  destroyed () {},
  methods: {
    onKeyDown (e) {
      if (e.keyCode === 13 && !this.isSubmittingData) {
        e.preventDefault()
        this.onSubmitClick()
      }
    },

    onSubmitClick () {
      if (this.validateForm()) {
        this.isSubmittingData = true
        this.successMessage = this.errorMessage = ''
        this.serverErrorMessages = {}

        this.model.ChangePassword()
          .then((response) => {
            const status = response.status
            const code = response.data?.code
            const message = response?.data?.message
            const success = response?.data?.success
            const data = response.data?.data

            if (status === 200 && code === 200 && success && data) {
              this.successMessage = message || ''
            }
          })
          .catch((e) => {
            const status = e.response.status
            const errors = e.response?.data?.errors || {}
            const message = e.response?.data?.message

            if (status === ApiResponseEnum.VALIDATOR_ERROR) {
              this.errorMessage = message || this.$t('Common.Error.Generic')
            } else if (status === ApiResponseEnum.GENERIC_ERROR) {
              if (errors.hasOwnProperty('email') || errors.hasOwnProperty('token')) { this.errorMessage = this.$t('Common.Error.Generic') }

              for (const key in errors) {
                if (errors.hasOwnProperty(key)) this.serverErrorMessages[toPascalCase(key)] = errors[key]
              }
            } else {
              this.errorMessage = message || this.$t('Common.Error.Generic')
            }
          })
          .finally(() => {
            if (this.$refs.form) this.$refs.form.reset()
            this.isSubmittingData = false
          })
      }
    },

    validateForm (deep = false) {
      this.isFormValid = true
      if (this.$refs?.form) this.isFormValid = this.$refs.form.validate()
      if (this.model?.validator) {
        this.isFormValid = (deep ? this.model.validate() : this.model.validator.validate())
        // eslint-disable-next-line no-console
        console.log(this.model.validator.constructor.name, this.model.errors)
      }
      return this.isFormValid
    },

    resetServerErrorMessage (fieldName) {
      this.serverErrorMessages && fieldName && delete this.serverErrorMessages[toPascalCase(fieldName)]
    }
  }
}

</script>

<style scoped>
/* Change Autocomplete styles in Chrome, Safari & Firefox*/
@-webkit-keyframes autofill {
  0%, 100% {
    color      : #FFFFFF;
    background : transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-delay     : 1s; /* Safari support - any positive time runs instantly */
  -webkit-animation-name      : autofill;
  -webkit-animation-fill-mode : both;
}
</style>

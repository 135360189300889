import Vue              from 'vue'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import Bugsnag          from '@bugsnag/js'
import DataStore        from '@/lib/data/dataStore/DataStore'
import { EventBus }     from '@/lib/events/eventBus'

export const setupBugsnag = (options = {}) => {
  const BugsnagKey = DataStore.App.Config?.LogBugsnagAdmin?.bugsnag_key || process.env.VUE_APP_LOGGING_BUGSNAG_KEY || ''
  const BugsnagEnvDefaultArr = ['production']
  const BugsnagEnvArr = DataStore.App.Config?.LogBugsnagAdmin?.bugsnag_env?.split(',')?.map(str => str.trim()) || BugsnagEnvDefaultArr
  const BugsnagEnv = DataStore.App.Config?.LogBugsnagAdmin?.bugsnag_env ? Array.isArray(BugsnagEnvArr) && BugsnagEnvArr.length ? BugsnagEnvArr : BugsnagEnvDefaultArr : BugsnagEnvDefaultArr

  const defaultOptions = {
    plugins          : [new BugsnagPluginVue(Vue)],
    apiKey           : BugsnagKey,
    appType          : 'browser',
    appVersion       : process.env.VUE_APP_VERSION || '1.0.0',
    autoDetectErrors : true,
    autoTrackSessions: true,
    collectUserIp    : true,
    enabledErrorTypes: {
      unhandledExceptions: true,
      unhandledRejections: true
    },
    enabledReleaseStages: BugsnagEnv,
    releaseStage        : process.env.VUE_APP_ENV,
    generateAnonymousId : true,
    metaData            : {
      project: {
        name: process.env.VUE_APP_NAME || ''
      }
    },
    onError: function (event) {
      // Adjust event here
    },
    onSession: function (session) {
      // const userId = getMyUserIdentifier() // a custom user resolver
      // session.setUser(userId)
    },
    redactedKeys: [
      'access_token', // exact match: "access_token"
      /^password$/i, // case-insensitive: "password", "PASSWORD", "PaSsWoRd"
      /^cc_/ // prefix match: "cc_number" "cc_cvv" "cc_expiry"
    ],
    trackInlineScripts: true
  }

  const initOptions = { ...defaultOptions, ...options }

  if (initOptions.apiKey && initOptions) {
    Bugsnag.start(initOptions)
    EventBus.$on('Auth:Login:Success', onUserLogin)
    // Bugsnag.notify(new Error('Test error'))
  }
}

const onUserLogin = (user) => {
  user?.Id && Bugsnag.setUser(user.Id, user.Email, user.FullName)
}

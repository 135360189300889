<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :max-width="userIsSuperAdmin ? 1200 : 800"
      persistent
      scrollable
    >
      <data-card
        v-if="model"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :sub-title="!userIsSuperAdmin ? model.Description : ''"
        :title="`${model.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)}`"
        :toolbar-extended="userIsSuperAdmin"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isFormSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #toolbar-extension>
          <v-tabs
            v-if="userIsSuperAdmin"
            v-model="tabs"
            background-color="grey darken-3"
            dark
            grow
          >
            <v-tabs-slider :color="$t(`Route.${parentComponentName}.Color`)" />

            <v-tab href="#tab-1">
              Γενικές Επιλογές
            </v-tab>

            <v-tab href="#tab-2">
              Επιλογές Ρυθμίσεων
            </v-tab>
          </v-tabs>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="isFormValid"
            >
              <v-alert
                :value="!!modelValueArrayError"
                class="mx-4"
                dense
                outlined
                type="error"
              >
                {{ modelValueArrayError }}
              </v-alert>

              <v-tabs-items v-model="tabs">
                <v-tab-item value="tab-1">
                  <template v-if="userIsSuperAdmin">
                    <v-container fluid>
                      <v-row dense>
                        <v-col cols="12">
                          <v-select
                            v-model="model.CompanyId"
                            :disabled="isReadOnly || !!model.Id"
                            :error-messages="serverErrorMessages['CompanyId']"
                            :hint="$t(`${parentComponentName}.Form.Fields.CompanyId.Hint`)"
                            :items="companies"
                            :label="$t(`${parentComponentName}.Form.Fields.CompanyId.Label`)"
                            :readonly="isReadOnly || !!model.Id"
                            :rules="model.validator.vuetifyFormFieldRules('CompanyId')"
                            item-text="Name"
                            item-value="Id"
                            prepend-inner-icon="mdi-domain"
                          >
                            <template #item="{on, attrs, item}">
                              <v-list-item
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-list-item-content>
                                  <v-list-item-title v-text="item.Name" />
                                  <v-list-item-subtitle v-text="item.Description" />
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-col>

                        <v-col cols="12">
                          <v-text-field
                            v-model="model.Item"
                            :clearable="!isReadOnly && !userIsSuperAdmin"
                            :disabled="isReadOnly || !userIsSuperAdmin"
                            :error-messages="serverErrorMessages['Item']"
                            :hint="$te(`${parentComponentName}.Form.Fields.Item.Hint`) ? $t(`${parentComponentName}.Form.Fields.Item.Hint`) : ''"
                            :label="$te(`${parentComponentName}.Form.Fields.Item.Label`) ? $t(`${parentComponentName}.Form.Fields.Item.Label`) : 'Item'"
                            :readonly="isReadOnly || !userIsSuperAdmin"
                            :rules="model.validator.vuetifyFormFieldRules('Item')"
                            prepend-inner-icon="mdi-label"
                            @focusin="resetServerErrorMessage('Item')"
                          />
                        </v-col>

                        <v-col cols="12">
                          <v-text-field
                            v-model="model.Group"
                            :clearable="!isReadOnly && !userIsSuperAdmin"
                            :disabled="isReadOnly || !userIsSuperAdmin"
                            :error-messages="serverErrorMessages['Group']"
                            :hint="$te(`${parentComponentName}.Form.Fields.Group.Hint`) ? $t(`${parentComponentName}.Form.Fields.Group.Hint`) : ''"
                            :label="$te(`${parentComponentName}.Form.Fields.Group.Label`) ? $t(`${parentComponentName}.Form.Fields.Group.Label`) : 'Group'"
                            :readonly="isReadOnly || !userIsSuperAdmin"
                            :rules="model.validator.vuetifyFormFieldRules('Group')"
                            prepend-inner-icon="mdi-group"
                            @focusin="resetServerErrorMessage('Group')"
                          />
                        </v-col>

                        <v-col cols="12">
                          <v-text-field
                            v-model="model.Description"
                            :clearable="!isReadOnly"
                            :disabled="isReadOnly"
                            :error-messages="serverErrorMessages['Description']"
                            :hint="$te(`${parentComponentName}.Form.Fields.Description.Hint`) ? $t(`${parentComponentName}.Form.Fields.Description.Hint`) : ''"
                            :label="$te(`${parentComponentName}.Form.Fields.Description.Label`) ? $t(`${parentComponentName}.Form.Fields.Description.Label`) : 'Description'"
                            :readonly="isReadOnly"
                            :rules="model.validator.vuetifyFormFieldRules('Description')"
                            prepend-inner-icon="mdi-note-text-outline"
                            @focusin="resetServerErrorMessage('Description')"
                          />
                        </v-col>

                        <v-col cols="12">
                          <v-select
                            v-model="model.ApiGroup"
                            :disabled="isReadOnly"
                            :error-messages="serverErrorMessages['ApiGroup']"
                            :hint="$t(`${parentComponentName}.Form.Fields.ApiGroup.Hint`)"
                            :items="Object.values(ApiSettingGroupEnum)"
                            :label="$t(`${parentComponentName}.Form.Fields.ApiGroup.Label`)"
                            :readonly="isReadOnly"
                            :rules="model.validator.vuetifyFormFieldRules('ApiGroup')"
                            multiple
                            prepend-inner-icon="mdi-api"
                            small-chips
                          >
                            <template #selection="{item}">
                              <v-chip
                                :color="getApiGroupColor(item)"
                                class="justify-center"
                                dark
                                label
                                small
                                style="min-width: 65px;"
                              >
                                {{ item.toUpperCase() }}
                              </v-chip>
                            </template>

                            <template #item="{on, attrs, item}">
                              <v-list-item
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-list-item-action>
                                  <v-checkbox v-model="attrs.inputValue" />
                                </v-list-item-action>

                                <v-list-item-content>
                                  <v-list-item-title>
                                    <v-chip
                                      :color="getApiGroupColor(item)"
                                      class="justify-center"
                                      dark
                                      label
                                      small
                                      style="min-width: 65px;"
                                    >
                                      {{ item.toUpperCase() }}
                                    </v-chip>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="4">
                          <label class="grey--text caption">
                            {{ $t(`${ parentComponentName }.Form.Fields.Active.Label`) }}
                          </label>
                          <v-switch
                            v-model="model.Active"
                            :disabled="isReadOnly"
                            :label="model.Active ? $t(`Common.Button.Toggle.ActiveF`) : $t(`Common.Button.Toggle.InactiveF`)"
                            :readonly="isReadOnly"
                            class="mt-1"
                            color="success"
                            inset
                          />
                        </v-col>

                        <v-col cols="4">
                          <label class="grey--text caption">
                            {{ $t(`${ parentComponentName }.Form.Fields.Global.Label`) }}
                          </label>
                          <v-switch
                            v-model="model.Global"
                            :disabled="isReadOnly"
                            :label="model.Global ? $t(`Common.Button.Yes`) : $t(`Common.Button.No`)"
                            :readonly="isReadOnly"
                            class="mt-1"
                            color="success"
                            inset
                          />
                        </v-col>

                        <v-col cols="4">
                          <label class="grey--text caption">
                            {{ $t(`${ parentComponentName }.Form.Fields.Admin.Label`) }}
                          </label>
                          <v-switch
                            v-model="model.Admin"
                            :disabled="isReadOnly"
                            :label="model.Admin ? $t(`Common.Button.Yes`) : $t(`Common.Button.No`)"
                            :readonly="isReadOnly"
                            class="mt-1"
                            color="success"
                            inset
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </v-tab-item>

                <v-tab-item value="tab-2">
                  <v-container fluid>
                    <v-row
                      v-if="userIsSuperAdmin"
                      dense
                    >
                      <v-col
                        class="text-right"
                        cols="12"
                      >
                        <v-btn
                          class="white--text"
                          color="primary"
                          depressed
                          fab
                          x-small
                          @click.stop="addRowItem"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row v-else>
                      <v-col cols="12">
                        <label class="grey--text caption">
                          {{ $t(`${ parentComponentName }.Form.Fields.Active.Label`) }}
                        </label>
                        <v-switch
                          v-model="model.Active"
                          :disabled="isReadOnly"
                          :label="model.Active ? $t(`Common.Button.Toggle.ActiveF`) : $t(`Common.Button.Toggle.InactiveF`)"
                          :readonly="isReadOnly"
                          class="mt-1"
                          color="success"
                          inset
                        />
                      </v-col>
                    </v-row>

                    <template v-for="(settingItem, index) in model.Value">
                      <template v-if="!userIsSuperAdmin">
                        <v-row
                          v-if="settingItem.Visible"
                          :key="`field-${index}`"
                          class="align-center justify-start"
                        >
                          <v-col>
                            <v-select
                              v-if="settingItem.Cast === CastValueEnum.BOOL.Value"
                              v-model="settingItem.Value"
                              :items="[{Name: $t('Common.Button.Yes'), Value: true}, {Name: $t('Common.Button.No'), Value: false}]"
                              :label="toTitleCase(settingItem.Key)"
                              :readonly="isReadOnly"
                              :rules="settingItem.validator.vuetifyFormFieldRules('Value')"
                              item-text="Name"
                              item-value="Value"
                              outlined
                            >
                              <template #item="{on, attrs, item}">
                                <v-list-item
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title v-text="item.Name" />
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-select>

                            <v-text-field
                              v-else
                              v-model="settingItem.Value"
                              :disabled="settingItem.Readonly"
                              :label="toTitleCase(settingItem.Key)"
                              :readonly="isReadOnly || settingItem.Readonly"
                              :rules="settingItem.validator.vuetifyFormFieldRules('Value')"
                              outlined
                            />
                          </v-col>

                          <v-col>
                            <v-switch
                              v-model="settingItem.Active"
                              :disabled="settingItem.Readonly"
                              :readonly="isReadOnly || settingItem.Readonly"
                              class="ma-0 pt-0 d-inline-flex"
                              color="success"
                              inset
                              label="Active"
                            />
                          </v-col>
                        </v-row>
                      </template>

                      <v-row
                        v-else
                        :key="`field-${index}`"
                        class="align-center justify-start"
                      >
                        <v-col cols="11">
                          <v-row class="align-center justify-center flex-nowrap">
                            <v-col cols="auto">
                              <v-text-field
                                v-model="settingItem.Key"
                                :readonly="isReadOnly"
                                :rules="settingItem.validator.vuetifyFormFieldRules('Key')"
                                label="Key"
                                outlined
                              />
                            </v-col>

                            <v-col>
                              <v-select
                                v-model="settingItem.Cast"
                                :items="castValuesList"
                                :readonly="isReadOnly"
                                :rules="settingItem.validator.vuetifyFormFieldRules('Cast')"
                                item-text="Name"
                                item-value="Value"
                                label="Cast"
                                outlined
                                @change="onSettingItemChange()"
                              >
                                <template #item="{on, attrs, item}">
                                  <v-list-item
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title v-text="item.Name" />
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-select>
                            </v-col>

                            <v-col>
                              <v-select
                                v-if="settingItem.Cast === CastValueEnum.BOOL.Value"
                                v-model="settingItem.Value"
                                :items="[{Name: $t('Common.Button.Yes'), Value: true}, {Name: $t('Common.Button.No'), Value: false}]"
                                :readonly="isReadOnly"
                                :rules="settingItem.validator.vuetifyFormFieldRules('Value')"
                                item-text="Name"
                                item-value="Value"
                                label="Value"
                                outlined
                              >
                                <template #item="{on, attrs, item}">
                                  <v-list-item
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title v-text="item.Name" />
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-select>

                              <v-text-field
                                v-else
                                v-model="settingItem.Value"
                                :readonly="isReadOnly"
                                :rules="settingItem.validator.vuetifyFormFieldRules('Value')"
                                label="Value"
                                outlined
                              />
                            </v-col>

                            <v-col>
                              <v-switch
                                v-model="settingItem.Active"
                                :readonly="isReadOnly"
                                class="ma-0 pt-0 d-inline-flex"
                                color="success"
                                inset
                                label="Active"
                              />
                            </v-col>

                            <v-col>
                              <v-switch
                                v-model="settingItem.Readonly"
                                :readonly="isReadOnly"
                                class="ma-0 pt-0 d-inline-flex"
                                color="success"
                                inset
                                label="Readonly"
                              />
                            </v-col>

                            <v-col>
                              <v-switch
                                v-model="settingItem.Visible"
                                :readonly="isReadOnly"
                                class="ma-0 pt-0 d-inline-flex"
                                color="success"
                                inset
                                label="Visible"
                              />
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col
                          v-if="!isReadOnly"
                          class="text-right"
                          cols="1"
                        >
                          <v-btn
                            class="white--text mb-6"
                            color="red"
                            depressed
                            fab
                            x-small
                            @click.stop="removeRowItem(index)"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="!!showGenericError"
            border="top"
            class="ma-0 pt-4 text-center"
            colored-border
            dense
            tile
            type="error"
          >
            {{ isBoolean(showGenericError) ? $t('Common.Error.Generic') : showGenericError }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />

            <v-btn
              :disabled="isFormSaving"
              large
              plain
              tile
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>

            <v-btn
              v-if="!isReadOnly"
              :disabled="isFormSaving"
              :loading="isFormSaving"
              color="success"
              depressed
              @click="onSave"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { themeUtils }          from '@/lib/utils'
import { isBoolean, isString } from '@/lib/utils/type'
import dialogVisible           from '@/mixins/dialog/dialogVisible'
import dialogData              from '@/mixins/dialog/dialogData'
import SettingModel            from '@/api/models/setting/SettingModel'
import { toTitleCase }         from '@/lib/utils/string'
import SettingItemModel        from '@/api/models/setting/SettingItemModel'
import CastValueEnum           from '@/api/enums/CastValueEnum'
import ApiSettingGroupEnum     from '@/api/enums/ApiSettingGroupEnum'
import settingColors           from '@/theme/default/views/system/setting/mixins/settingColors'

const DataCard = () => themeUtils.importThemeComponent('components/common/DataCard')

export default {
  name      : 'SettingEditDialog',
  components: { DataCard },
  directives: {},
  mixins    : [dialogVisible, dialogData, settingColors],
  props     : {
    companies: {
      type   : Array,
      default: () => []
    }
  },
  enums: {
    CastValueEnum,
    ApiSettingGroupEnum
  },
  dataModel: SettingModel,
  data () {
    return {
      tabs: 'tab-1'
    }
  },
  computed: {
    modelValueArrayError () {
      return this.model.errors && this.model.errors.Value && this.model.errors.Value.length > 0 && isString(this.model.errors.Value[0]) && this.model.errors.Value[0] || ''
    },

    castValuesList () {
      return Object.values(CastValueEnum).filter(val => val.Active)
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.tabs = this.userIsSuperAdmin ? 'tab-1' : 'tab-2'
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    ...{
      isBoolean,
      toTitleCase
    },

    addRowItem () {
      this.model.Value.push(new SettingItemModel())
      this.validateForm()
    },

    removeRowItem (index) {
      this.model.Value.splice(index, 1)
      this.validateForm()
    },

    onSettingItemChange () {
      // Force UI Update
      this.model.Value.splice(0, 0)
    },

    onSave () {
      this.saveFromData()
      this.onSettingItemChange()
    }
  }
}
</script>

<style scoped>

</style>

import BaseResource from '@/lib/data/resource/BaseResource'

export default class RoleResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.Id ?? null
    this.CompanyId = payload.CompanyId ?? null
    this.UsersCount = payload.UsersCount ?? 0
    this.Name = payload.Name ?? ''
    this.Permissions = payload.Permissions ?? []
    this.Admin = payload.Admin ?? false
    this.Color = payload.Color ?? '#B500FF'
    this.Description = payload.Description ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}

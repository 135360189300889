export default {
  dark: {
    anchor   : '#1976d2',
    primary  : '#1976D2',
    secondary: '#424242',
    accent   : '#82B1FF',
    error    : '#FF5252',
    info     : '#2196F3',
    success  : '#4CAF50',
    warning  : '#FB8C00',

    override                  : '#11224E',
    'override-brand-dark-blue': '#041F30'
  },

  light: {
    anchor   : '#2196F3',
    primary  : '#2196F3',
    secondary: '#424242',
    accent   : '#FF4081',
    error    : '#FF5252',
    info     : '#2196F3',
    success  : '#4CAF50',
    warning  : '#FB8C00',

    override             : '#11224E',
    'override-ui-blue-01': '#11224E'
  }
}

import ApiSettingGroupEnum from '@/api/enums/ApiSettingGroupEnum'

export default {
  Title: 'Ρυθμίσεις',

  System: {
    Title: 'Σύστημα'
  },

  Table: {
    Button: {
      New: 'Νέα Ρύθμιση'
    },

    Headers: {
      Id         : '#',
      CompanyName: 'ΕΤΑΙΡΕΙΑ',
      Group      : 'ΟΜΑΔΑ',
      Description: 'ΠΕΡΙΓΡΑΦΗ',
      Value      : 'ΤΙΜΗ',
      Active     : 'ΕΝΕΡΓΟ',
      Global     : 'ΓΕΝΙΚΗ',
      Admin      : 'ΔΙΑΧΕΙΡΙΣΤΗ',
      ApiGroup   : 'API GROUP',
      Actions    : ''
    },

    Filter: {
      CompanyId: {
        Type       : 'v-select',
        Label      : 'Εταιρείες',
        PlaceHolder: 'Όλες οι Εταιρείες',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },

      ApiGroup: {
        Type        : 'v-select',
        CustomFilter: true,
        Multiple    : true,
        Chips       : true,
        Label       : 'Ομάδες API',
        PlaceHolder : 'Όλες οι Ομάδες API',
        ItemText    : 'Name',
        ItemValue   : 'Id',
        Items       : Object.values(ApiSettingGroupEnum).map(obj => {
          return {
            Name: obj.toUpperCase(),
            Id  : obj
          }
        })
      },

      Global: {
        Type       : 'v-btn-toggle',
        Label      : 'Γενικές Ρυθμίσεις',
        PlaceHolder: 'Όλες οι ρυθμίσεις',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: 'null',
            Name : 'Όλες'
          },
          {
            Value: 1,
            Name : 'Ναι'
          },
          {
            Value: 0,
            Name : 'Όχι'
          }
        ]
      },

      Admin: {
        Type       : 'v-btn-toggle',
        Label      : 'Μόνο για Διαχειριστές',
        PlaceHolder: 'Όλες οι ρυθμίσεις',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: 'null',
            Name : 'Όλες'
          },
          {
            Value: 1,
            Name : 'Ναι'
          },
          {
            Value: 0,
            Name : 'Όχι'
          }
        ]
      },

      Active: {
        Type       : 'v-btn-toggle',
        Label      : 'Ρυθμίσεις',
        PlaceHolder: 'Όλες οι ρυθμίσεις',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: 'null',
            Name : 'Όλες'
          },
          {
            Value: 1,
            Name : 'Ενεργές'
          },
          {
            Value: 0,
            Name : 'Ανενεργές'
          }
        ]
      }
    }
  },

  Confirm: {
    Delete: {
      Title   : 'Διαγραφή Ρύθμισης {Id}',
      SubTitle: 'Επιβεβαίωση Διαγραφής Ρύθμισης',
      Body    : 'Είστε σίγουροι ότι θέλετε προχωρήσετε στην οριστική διαγραφή της Ρύθμισης {Id};'
    }
  },

  CopyDialog: {
    Title: 'Ρυθμίσεων',
    Form : {
      Fields: {
        CompanyIds: {
          Label: 'Εταιρείες',
          Hint : ''
        },
        ForceUpdate: {
          Label: 'Ενημέρωση υπάρχοντων ρυθμίσεων',
          Hint : ''
        }
      }
    }
  },

  Form: {
    Title : 'Ρύθμισης',
    Fields: {
      Item: {
        Label: 'Key Ρύθμισης',
        Hint : ''
      },
      Group: {
        Label: 'Ομαδα Ρύθμισης',
        Hint : ''
      },
      Description: {
        Label: 'Περιγραφή',
        Hint : ''
      },
      CompanyId: {
        Label: 'Εταιρεία',
        Hint : ''
      },
      CompanyName: {
        Label: 'Εταιρεία',
        Hint : ''
      },
      Value: {
        Label: 'Τιμή',
        Hint : ''
      },
      Email: {
        Label: 'Email',
        Hint : ''
      },
      ApiGroup: {
        Label: 'Ομάδα API',
        Hint : ''
      },
      Global: {
        Label: 'Γενική Ρύθμιση',
        Hint : ''
      },
      Admin: {
        Label: 'Μόνο για διαχειριστές',
        Hint : ''
      },
      Active: {
        Label: 'Ρύθμιση',
        Hint : ''
      }
    }
  }
}

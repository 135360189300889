import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export const numberMask = createNumberMask({
  prefix                   : '',
  suffix                   : '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol : ',',
  allowDecimal             : true,
  decimalSymbol            : '.',
  decimalLimit             : 2,
  integerLimit             : null,
  requireDecimal           : false,
  allowNegative            : false,
  allowLeadingZeroes       : false
})

export const formatVuetifyDate = (date) => {
  if (!date) return ''

  const [year, month, day] = date.split('-')
  return `${ day }/${ month }/${ year }`
}

export const parseVuetifyDate = (date) => {
  if (!date) return ''

  const [day, month, year] = date.split('/')
  return `${ year }-${ month.padStart(2, '0') }-${ day.padStart(2, '0') }`
}

/**
 * Generate a time mask based on input value (23:59)
 * @param {string} value
 * @returns {(RegExp|string)[]|RegExp[]}
 */
export const timeMask = (value) => {
  const hours = [
    /[0-2]/,
    value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/
  ]
  const minutes = [/[0-5]/, /[0-9]/]
  return value.length > 2 ? [...hours, ':', ...minutes] : hours
}

/**
 * Generate a date mask (16/12/2021)
 * @param {string} value
 * @returns {[RegExp, string, RegExp, string, RegExp]}
 */
export const dateSlashMask = (value) => {
  const day = [/[0-3]/, value.charAt(0) === '0' ? /[1-9]/ : value.charAt(0) === '1' || value.charAt(0) === '2' ? /[0-9]/ : /[01]/]
  const month = [/[0-1]/, value.charAt(3) === '0' ? /[1-9]/ : /[0-2]/]
  const year = [/[12]/, value.charAt(6) === '2' ? /[0]/ : /[9]/, /[0-9]/, /[0-9]/]
  return [...day, '/', ...month, '/', ...year]
}

/**
 * Generate a date mask (16-12-2021)
 * @param {string} value
 * @returns {[RegExp, string, RegExp, string, RegExp]}
 */
export const dateDashMask = (value) => {
  const day = [/[0-3]/, value.charAt(0) === '0' ? /[1-9]/ : value.charAt(0) === '1' || value.charAt(0) === '2' ? /[0-9]/ : /[01]/]
  const month = [/[0-1]/, value.charAt(3) === '0' ? /[1-9]/ : /[0-2]/]
  const year = [/[12]/, value.charAt(6) === '2' ? /[0]/ : /[9]/, /[0-9]/, /[0-9]/]
  return [...day, '-', ...month, '-', ...year]
}

<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      fullscreen
      persistent
      scrollable
    >
      <data-card
        v-if="model"
        :color="$t(`Route.Permissions.Color`)"
        :colored-border="true"
        :icon="$t(`Route.Permissions.Icon`)"
        :sub-title="`${$t(`${parentComponentName}.Permissions.Title`)} '${model.Name}'`"
        :title="`${model.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)}`"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isFormSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="isFormValid"
            >
              <v-data-table
                :headers="headers"
                :items="model.Permissions"
                :items-per-page="-1"
                fixed-header
                height="calc(100vh - 152px - 16px)"
                hide-default-footer
              >
                <template #[`item.Permission`]="{item}">
                  {{ item.Permission }}
                </template>

                <template
                  v-if="userIsSuperAdmin"
                  #[`item.Api`]="{item}"
                >
                  <v-checkbox
                    v-if="item.Action.includes(PermissionEnum.API.toLowerCase())"
                    v-model="item.RolePermissions"
                    :color="getPermissionColor(PermissionEnum.API.toLowerCase())"
                    :value="PermissionEnum.API.toLowerCase()"
                    class="d-inline-block"
                    hide-details
                    primary
                    readonly
                    @change="onCheckboxChange(PermissionEnum.API.toLowerCase(), item)"
                  />
                </template>

                <template #[`item.Access`]="{item}">
                  <v-checkbox
                    v-if="item.Action.includes(PermissionEnum.ACCESS.toLowerCase())"
                    v-model="item.RolePermissions"
                    :color="getPermissionColor(PermissionEnum.ACCESS.toLowerCase())"
                    :disabled="!userCanManage"
                    :value="PermissionEnum.ACCESS.toLowerCase()"
                    class="d-inline-block"
                    hide-details
                    primary
                    @change="onCheckboxChange(PermissionEnum.ACCESS.toLowerCase(), item)"
                  />
                </template>

                <template #[`item.Manage`]="{item}">
                  <v-checkbox
                    v-if="item.Action.includes(PermissionEnum.MANAGE.toLowerCase())"
                    v-model="item.RolePermissions"
                    :color="getPermissionColor(PermissionEnum.MANAGE.toLowerCase())"
                    :disabled="!userCanManage"
                    :value="PermissionEnum.MANAGE.toLowerCase()"
                    class="d-inline-block"
                    hide-details
                    primary
                    @change="onCheckboxChange(PermissionEnum.MANAGE.toLowerCase(), item)"
                  />
                </template>

                <template #[`item.Add`]="{item}">
                  <v-checkbox
                    v-if="item.Action.includes(PermissionEnum.ADD.toLowerCase())"
                    v-model="item.RolePermissions"
                    :color="getPermissionColor(PermissionEnum.ADD.toLowerCase())"
                    :disabled="!userCanManage"
                    :value="PermissionEnum.ADD.toLowerCase()"
                    class="d-inline-block"
                    hide-details
                    primary
                    @change="onCheckboxChange(PermissionEnum.ADD.toLowerCase(), item)"
                  />
                </template>

                <template #[`item.Delete`]="{item}">
                  <v-checkbox
                    v-if="item.Action.includes(PermissionEnum.DELETE.toLowerCase())"
                    v-model="item.RolePermissions"
                    :color="getPermissionColor(PermissionEnum.DELETE.toLowerCase())"
                    :disabled="!userCanManage"
                    :value="PermissionEnum.DELETE.toLowerCase()"
                    class="d-inline-block"
                    hide-details
                    primary
                    @change="onCheckboxChange(PermissionEnum.DELETE.toLowerCase(), item)"
                  />
                </template>

                <template
                  v-if="userIsSuperAdmin"
                  #[`item.Toolbar`]="{item}"
                >
                  <v-checkbox
                    v-if="item.Action.includes(PermissionEnum.TOOLBAR.toLowerCase())"
                    v-model="item.RolePermissions"
                    :color="getPermissionColor(PermissionEnum.TOOLBAR.toLowerCase())"
                    :disabled="!userCanManage"
                    :value="PermissionEnum.TOOLBAR.toLowerCase()"
                    class="d-inline-block"
                    hide-details
                    primary
                    @change="onCheckboxChange(PermissionEnum.TOOLBAR.toLowerCase(), item)"
                  />
                </template>

                <template
                  v-if="userIsSuperAdmin"
                  #[`item.Menu`]="{item}"
                >
                  <v-checkbox
                    v-if="item.Action.includes(PermissionEnum.MENU.toLowerCase())"
                    v-model="item.RolePermissions"
                    :color="getPermissionColor(PermissionEnum.MENU.toLowerCase())"
                    :disabled="!userCanManage"
                    :value="PermissionEnum.MENU.toLowerCase()"
                    class="d-inline-block"
                    hide-details
                    primary
                    @change="onCheckboxChange(PermissionEnum.MENU.toLowerCase(), item)"
                  />
                </template>

                <template
                  v-if="userIsSuperAdmin && userCanDelete"
                  #[`item.Actions`]="{item}"
                >
                  <v-tooltip
                    v-if="userCanDelete"
                    :open-delay="650"
                    bottom
                    color="red"
                  >
                    <template #activator="{on}">
                      <v-btn
                        v-if="userCanDelete"
                        class="ml-1 white--text"
                        color="red"
                        depressed
                        fab
                        x-small
                        v-on="on"
                        @click.stop="deleteItem(item)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <span v-text="$t('Common.Button.Delete')" />
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="!!showGenericError"
            border="top"
            class="ma-0 pt-4 text-center"
            colored-border
            dense
            tile
            type="error"
          >
            {{ isBoolean(showGenericError) ? $t('Common.Error.Generic') : showGenericError }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />

            <v-btn
              :disabled="isFormSaving"
              large
              plain
              tile
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>

            <v-btn
              v-if="!isReadOnly"
              :disabled="isFormSaving"
              :loading="isFormSaving"
              color="success"
              depressed
              @click="saveFromData"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { themeUtils }   from '@/lib/utils'
import { isBoolean }    from '@/lib/utils/type'
import dialogVisible    from '@/mixins/dialog/dialogVisible'
import dialogData       from '@/mixins/dialog/dialogData'
import RoleModel        from '@/api/models/role/RoleModel'
import permissionColors from '@/theme/default/views/system/permission/mixins/permissionColors'
import PermissionEnum   from '@/api/enums/PermissionEnum'

const DataCard = () => themeUtils.importThemeComponent('components/common/DataCard')

export default {
  name      : 'RolePermissionsEditDialog',
  components: { DataCard },
  directives: {},
  mixins    : [dialogVisible, dialogData, permissionColors],
  props     : {
    companies: {
      type   : Array,
      default: () => []
    }
  },
  enums    : {},
  dataModel: RoleModel,
  data () {
    return {}
  },
  computed: {
    headers () {
      if (this.userIsSuperAdmin) {
        return [
          {
            text    : this.$t('Roles.Permissions.Headers.Right'),
            align   : 'left',
            value   : 'Permission',
            sortable: false
          },
          {
            text    : this.$t('Roles.Permissions.Headers.Api'),
            align   : 'center',
            value   : 'Api',
            sortable: false
          },
          {
            text    : this.$t('Roles.Permissions.Headers.Access'),
            align   : 'center',
            value   : 'Access',
            sortable: false
          },
          {
            text    : this.$t('Roles.Permissions.Headers.Manage'),
            align   : 'center',
            value   : 'Manage',
            sortable: false
          },
          {
            text    : this.$t('Roles.Permissions.Headers.Add'),
            align   : 'center',
            value   : 'Add',
            sortable: false
          },
          {
            text    : this.$t('Roles.Permissions.Headers.Delete'),
            align   : 'center',
            value   : 'Delete',
            sortable: false
          },
          {
            text    : this.$t('Roles.Permissions.Headers.Toolbar'),
            align   : 'center',
            value   : 'Toolbar',
            sortable: false
          },
          {
            text    : this.$t('Roles.Permissions.Headers.Menu'),
            align   : 'center',
            value   : 'Menu',
            sortable: false
          },
          {
            text    : '',
            align   : 'right',
            value   : 'Actions',
            sortable: false
          }
        ]
      }
      return [
        {
          text    : this.$t('Roles.Permissions.Headers.Right'),
          align   : 'left',
          value   : 'Permission',
          sortable: false
        },
        {
          text    : this.$t('Roles.Permissions.Headers.Access'),
          align   : 'center',
          value   : 'Access',
          sortable: false
        },
        {
          text    : this.$t('Roles.Permissions.Headers.Manage'),
          align   : 'center',
          value   : 'Manage',
          sortable: false
        },
        {
          text    : this.$t('Roles.Permissions.Headers.Add'),
          align   : 'center',
          value   : 'Add',
          sortable: false
        },
        {
          text    : this.$t('Roles.Permissions.Headers.Delete'),
          align   : 'center',
          value   : 'Delete',
          sortable: false
        }
      ]
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    ...{ isBoolean },

    deleteItem (item) {
      const index = this.model.Permissions.findIndex(permission => permission.Id === item.Id)
      if (index > -1) this.model.Permissions.splice(index, 1)
    },

    onCheckboxChange (type, permission) {
      const AllButApiAndAccess = [PermissionEnum.MANAGE.toLowerCase(), PermissionEnum.ADD.toLowerCase(), PermissionEnum.DELETE.toLowerCase(), PermissionEnum.TOOLBAR.toLowerCase(), PermissionEnum.MENU.toLowerCase()]

      if (AllButApiAndAccess.includes(type)) {
        if (!permission.RolePermissions.includes(PermissionEnum.ACCESS.toLowerCase())) {
          permission.RolePermissions.push(PermissionEnum.ACCESS.toLowerCase())
          if (!permission.RolePermissions.includes(PermissionEnum.API.toLowerCase())) permission.RolePermissions.push(PermissionEnum.API.toLowerCase())
        }
      }

      if (!permission.RolePermissions.includes(PermissionEnum.API.toLowerCase()) && permission.RolePermissions.includes(PermissionEnum.ACCESS.toLowerCase())) {
        permission.RolePermissions.push(PermissionEnum.API.toLowerCase())
      } else {
        if (!permission.RolePermissions.includes(PermissionEnum.ACCESS.toLowerCase())) {
          if (this.permissionHasActiveRequires(permission)) permission.RolePermissions = permission.RolePermissions.filter(el => el !== PermissionEnum.API.toLowerCase())
          permission.RolePermissions = permission.RolePermissions.filter(el => el !== PermissionEnum.MANAGE.toLowerCase())
          permission.RolePermissions = permission.RolePermissions.filter(el => el !== PermissionEnum.ADD.toLowerCase())
          permission.RolePermissions = permission.RolePermissions.filter(el => el !== PermissionEnum.DELETE.toLowerCase())
          permission.RolePermissions = permission.RolePermissions.filter(el => el !== PermissionEnum.TOOLBAR.toLowerCase())
          permission.RolePermissions = permission.RolePermissions.filter(el => el !== PermissionEnum.MENU.toLowerCase())
        }
      }

      if (permission.Requires.Parent) {
        const foundPermission = this.model.Permissions.find(p => parseInt(p.Id) === parseInt(permission.Requires.Parent))
        if (foundPermission) {
          if (!foundPermission.RolePermissions.includes(PermissionEnum.API.toLowerCase()) && permission.RolePermissions.includes(PermissionEnum.API.toLowerCase())) foundPermission.RolePermissions.push(PermissionEnum.API.toLowerCase())
          if (!foundPermission.RolePermissions.includes(PermissionEnum.ACCESS.toLowerCase()) && permission.RolePermissions.includes(PermissionEnum.ACCESS.toLowerCase())) foundPermission.RolePermissions.push(PermissionEnum.ACCESS.toLowerCase())
        }
      }

      const permissionChildren = this.permissionGetChildren(permission)
      permissionChildren.forEach(p => {
        if (!p.RolePermissions.includes(PermissionEnum.API.toLowerCase()) && permission.RolePermissions.includes(PermissionEnum.API.toLowerCase())) {
          p.RolePermissions.push(PermissionEnum.API.toLowerCase())
        } else if (type === PermissionEnum.ACCESS.toLowerCase()) {
          p.RolePermissions = p.RolePermissions.filter(el => el !== PermissionEnum.API.toLowerCase())
        }
        if (!p.RolePermissions.includes(PermissionEnum.ACCESS.toLowerCase()) && permission.RolePermissions.includes(PermissionEnum.ACCESS.toLowerCase())) {
          p.RolePermissions.push(PermissionEnum.ACCESS.toLowerCase())
        } else if (type === PermissionEnum.ACCESS.toLowerCase()) {
          p.RolePermissions = p.RolePermissions.filter(el => el !== PermissionEnum.ACCESS.toLowerCase())
        }
      })

      if (Array.isArray(permission.Requires.Permissions) && permission.Requires.Permissions.length) {
        this.model.Permissions.forEach(permission => {
          if (!Array.isArray(permission.Requires.Permissions)) permission.Requires.Permissions = []
          permission.Requires.Permissions.forEach(r => {
            const foundPermission = this.model.Permissions.find(p => parseInt(p.Id) === parseInt(r))
            if (foundPermission) {
              if (!foundPermission.RolePermissions.includes(PermissionEnum.API.toLowerCase())) {
                if (permission.RolePermissions.includes(PermissionEnum.ACCESS.toLowerCase()) && (!foundPermission.RolePermissions.includes(PermissionEnum.API.toLowerCase()))) foundPermission.RolePermissions.push(PermissionEnum.API.toLowerCase())
              } else {
                if (!foundPermission.RolePermissions.includes(PermissionEnum.ACCESS.toLowerCase()) && this.model.Permissions.filter(p => (parseInt(p.Id) !== parseInt(permission.Id)) && (!p.RolePermissions.includes(PermissionEnum.ACCESS.toLowerCase()) && !p.RolePermissions.includes(PermissionEnum.API.toLowerCase())) && (p.Requires.Permissions ? p.Requires.Permissions.includes(String(permission.Id)) : false)).length <= 0) {
                  if (!permission.RolePermissions.includes(PermissionEnum.ACCESS.toLowerCase())) foundPermission.RolePermissions = foundPermission.RolePermissions.filter(el => el !== PermissionEnum.API.toLowerCase())
                }
              }
            }
          })
        })
      }
    },

    permissionHasActiveRequires (permission) {
      const retVal = this.model.Permissions.filter(p => {
        if (!Array.isArray(p.Requires.Permissions)) p.Requires.Permissions = []
        return parseInt(p.Id) !== parseInt(permission.Id) &&
          p.Requires.Permissions.includes(parseInt(permission.Id)) &&
          p.RolePermissions.includes(PermissionEnum.ACCESS.toLowerCase()) && p.RolePermissions.includes(PermissionEnum.API.toLowerCase())
      })
      return retVal.length <= 0
    },

    permissionGetChildren (permission) {
      return this.model.Permissions.filter(p => parseInt(p.Requires.Parent) === parseInt(permission.Id))
    }
  }
}
</script>

<style scoped>

</style>

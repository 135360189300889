<template>
  <div class="d-inline-flex">
    <v-progress-circular
      :color="color"
      :size="size"
      :value="progressValue"
      :width="width"
    >
      {{ formattedTimeLeft }}
    </v-progress-circular>
  </div>
</template>

<script>
export default {
  name      : 'CountdownTimer',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    timeLimit: {
      type   : Number,
      default: 60
    },
    alertThreshold: {
      type   : Number,
      default: 10
    },
    alertColor: {
      type   : String,
      default: 'red'
    },
    warningThreshold: {
      type   : Number,
      default: 20
    },
    warningColor: {
      type   : String,
      default: 'orange'
    },
    infoColor: {
      type   : String,
      default: 'primary'
    },
    size: {
      type   : Number,
      default: 44
    },
    width: {
      type   : Number,
      default: 2
    }
  },
  enums: {},
  data () {
    return {
      timePassed   : 0,
      timerInterval: null,
      colorCodes   : {
        info: {
          color: this.infoColor
        },
        warning: {
          color    : this.warningColor,
          threshold: this.warningThreshold
        },
        alert: {
          color    : this.alertColor,
          threshold: this.alertThreshold
        }
      }
    }
  },
  computed: {
    timeLeft () {
      return this.timeLimit - this.timePassed
    },

    formattedTimeLeft () {
      const timeLeft = this.timeLeft
      const minutes = Math.floor(timeLeft / 60)
      let seconds = timeLeft % 60
      if (seconds < 10) {
        seconds = `0${ seconds }`
      }
      return `${ minutes }:${ seconds }`
    },

    progressValue () {
      return this.logScaleMap(this.timeLeft, 0, this.timeLimit, 0, 100)
    },

    color () {
      const {
        alert,
        warning,
        info
      } = this.colorCodes
      if (this.timeLeft <= alert.threshold) {
        return alert.color
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color
      } else {
        return info.color
      }
    }
  },
  watch: {
    timeLeft (newVal) {
      this.$emit('update', newVal)
      if (newVal <= 0) {
        this.stopTimer()
        this.$emit('completed')
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.startTimer()
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.stopTimer()
  },
  destroyed () {},
  methods: {
    startTimer () {
      this.stopTimer()
      if (this.timeLimit <= 0) return
      this.timerInterval = setInterval(() => (this.timePassed++), 1000)
      this.$emit('started')
    },

    stopTimer () {
      clearInterval(this.timerInterval)
    },

    logScaleMap (num, min1, max1, min2, max2, round = false, constrainMin = true, constrainMax = true) {
      if (constrainMin && num < min1) return min2
      if (constrainMax && num > max1) return max2

      const num1 = (num - min1) / (max1 - min1)
      const num2 = (num1 * (max2 - min2)) + min2

      if (round) return Math.round(num2)
      return num2
    }
  }
}
</script>

<style scoped>

</style>

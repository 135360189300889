<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="fill-height">
          <v-card-title class="pa-2 d-block">
            <v-toolbar
              :color="!$vuetify.theme.dark ? 'grey lighten-3' : ''"
              extension-height="60"
              flat
            >
              <template #default>
                <v-btn
                  v-if="userCanAdd"
                  :fab="$vuetify.breakpoint.mobile"
                  :small="$vuetify.breakpoint.mobile"
                  class="px-2 mr-1"
                  color="primary"
                  @click="createItem()"
                >
                  <v-icon :class="{'mr-1': !$vuetify.breakpoint.mobile}">
                    mdi-plus
                  </v-icon>
                  <template v-if="!$vuetify.breakpoint.mobile">
                    {{ $t(`${ $options.name }.Table.Button.New`).toLocaleUpperCase($i18n.locale) }}
                  </template>
                </v-btn>

                <div class="flex-grow-1" />

                <data-table-search
                  v-if="dataTable.search.enabled"
                  v-model="dataTable.search.terms"
                />

                <data-table-filter
                  v-model="dataTable"
                  :filters="filterData"
                />
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            :headers="dataTable.headers"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #[`item.Name`]="{item}">
              <div>
                {{ item.Name }}
              </div>
              <div class="caption grey--text">
                {{ item.Description }}
              </div>
            </template>

            <template #[`item.Active`]="{item}">
              <v-switch
                v-model="item.Active"
                :disabled="!userCanManage"
                :readonly="!userCanManage"
                class="ma-0 pt-0 d-inline-flex"
                color="success"
                hide-details
                inset
                @change="item.Update()"
              />
            </template>

            <template
              v-if="userCanDelete || userCanManage || userCanAccess"
              #[`item.Actions`]="{item}"
            >
              <v-speed-dial
                v-model="item.fab"
                direction="left"
                right
              >
                <template #activator>
                  <v-btn
                    v-model="item.fab"
                    color="blue darken-2"
                    dark
                    depressed
                    fab
                    x-small
                  >
                    <v-icon v-if="item.fab">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-menu
                    </v-icon>
                  </v-btn>
                </template>

                <v-tooltip
                  v-if="userCanDelete"
                  :open-delay="650"
                  bottom
                  color="red"
                >
                  <template #activator="{on}">
                    <v-btn
                      v-if="userCanDelete"
                      :disabled="item.loadingDelete"
                      :loading="item.loadingDelete"
                      class="ml-1 white--text"
                      color="red"
                      depressed
                      fab
                      x-small
                      v-on="on"
                      @click.native="item.fab = false"
                      @click.stop="deleteItem(item)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$t('Common.Button.Delete')" />
                </v-tooltip>

                <v-tooltip
                  v-if="userCanAccess"
                  :open-delay="650"
                  bottom
                  color="info"
                >
                  <template #activator="{on}">
                    <v-btn
                      v-if="userCanAccess"
                      :disabled="item.loadingView"
                      :loading="item.loadingView"
                      class="ml-1 white--text"
                      color="info"
                      depressed
                      fab
                      x-small
                      v-on="on"
                      @click.native="item.fab = false"
                      @click.stop="viewItem(item)"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$t('Common.Button.View')" />
                </v-tooltip>

                <v-tooltip
                  v-if="userCanManage"
                  :open-delay="650"
                  bottom
                  color="grey"
                >
                  <template #activator="{on}">
                    <v-btn
                      v-if="userCanManage"
                      :disabled="item.loadingEdit"
                      :loading="item.loadingEdit"
                      class="ml-1 white--text"
                      color="grey"
                      depressed
                      fab
                      x-small
                      v-on="on"
                      @click.native="item.fab = false"
                      @click.stop="editItem(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$t('Common.Button.Edit')" />
                </v-tooltip>
              </v-speed-dial>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <company-edit-dialog
      v-if="userCanAccess || userCanAdd || userCanManage"
      :item="selectedItem"
      :item-view-mode="itemViewMode"
      :visible.sync="editDialogVisible"
      @form:save:success="getData"
    />
  </v-container>
</template>

<script>
import DataTable        from '@/mixins/table/dataTable'
import DataTableActions from '@/mixins/table/dataTableActions'
import i18nRouteMeta    from '@/mixins/i18n/i18nRouteMeta'
import CompanyModel     from '@/api/models/company/CompanyModel'
import { themeUtils }   from '@/lib/utils'

const DataTableFilter = () => themeUtils.importThemeComponent('components/common/dataTable/DataTableFilter')
const DataTableSearch = () => themeUtils.importThemeComponent('components/common/dataTable/DataTableSearch')
const CompanyEditDialog = () => themeUtils.importThemeComponent('views/system/company/CompanyEditDialog')

export default {
  name      : 'Companies',
  components: {
    DataTableSearch,
    DataTableFilter,
    CompanyEditDialog
  },
  directives: {},
  mixins    : [i18nRouteMeta, DataTable, DataTableActions],
  props     : {},
  dataStore : {},
  enums     : {},
  dataModel : CompanyModel,
  data () {
    return {
      dataTable: {
        customFilter: {},
        filter      : {
          Active: null
        },
        options: {
          sortBy  : ['Name'],
          sortDesc: [false]
        },
        headers: [
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Id`) ? this.$t(`${ this.$options.name }.Table.Headers.Id`) : '#',
            value   : 'Id',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Name`) ? this.$t(`${ this.$options.name }.Table.Headers.Name`) : 'Name',
            value   : 'Name',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.CompanyName`) ? this.$t(`${ this.$options.name }.Table.Headers.CompanyName`) : 'CompanyName',
            value   : 'CompanyName',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Active`) ? this.$t(`${ this.$options.name }.Table.Headers.Active`) : 'Active',
            value   : 'Active',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.CreatedAt`) ? this.$t(`${ this.$options.name }.Table.Headers.CreatedAt`) : 'CreatedAt',
            value   : 'CreatedAt',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Actions`) ? this.$t(`${ this.$options.name }.Table.Headers.Actions`) : '',
            value   : 'Actions',
            align   : 'right',
            sortable: false,
            width   : 40
          }
        ]
      }
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>

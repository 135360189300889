import BaseModel           from '@/lib/data/model/BaseModel'
import PermissionValidator from '@/api/validations/permission/PermissionValidator'
import PermissionResource  from '@/api/resources/permission/PermissionResource'
import API                 from '@/api/Api'

export default class PermissionModel extends BaseModel {
  constructor (payload = {}, resource = PermissionResource, validator = PermissionValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */

  async Get () {
    return await API.Resource.Permission.Get(this.Id)
  }

  async Create () {
    return await API.Resource.Permission.Create(this.clone())
  }

  async Update () {
    return await API.Resource.Permission.Update(this.Id, this.clone())
  }

  async UpdateOrCreate () {
    return await API.Resource.Permission.UpdateOrCreate(this.clone())
  }

  async Delete () {
    return await API.Resource.Permission.Delete(this.Id)
  }
}

export default {

  Title: 'Εταιρείες',

  Table: {
    Button: {
      New: 'Νέα Εταιρεία'
    },

    Headers: {
      Id         : '#',
      CompanyName: 'ΓΟΝΙΚΗ ΕΤΑΙΡΕΙΑ',
      Name       : 'ΟΝΟΜΑ',
      Description: 'ΠΕΡΙΓΡΑΦΗ',
      CreatedAt  : 'ΕΓΓΡΑΦΗ',
      Active     : 'ΕΝΕΡΓΗ',
      Actions    : ''
    },

    Filter: {
      Active: {
        Type       : 'v-btn-toggle',
        Label      : 'Εταιρείες',
        PlaceHolder: 'Όλες οι Εταιρείες',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: 'null',
            Name : 'Όλες'
          },
          {
            Value: 1,
            Name : 'Ενεργές'
          },
          {
            Value: 0,
            Name : 'Ανενεργές'
          }
        ]
      }
    }
  },

  Confirm: {
    Delete: {
      Title   : 'Διαγραφή Εταιρείας {Id}',
      SubTitle: 'Επιβεβαίωση Διαγραφής Εταιρείας',
      Body    : 'Είστε σίγουροι ότι θέλετε προχωρήσετε στην οριστική διαγραφή της Εταιρείας {Id};'
    }
  },

  Form: {
    Title : 'Εταιρείας',
    Fields: {
      CompanyId: {
        Label: 'Γονική Εταιρεία',
        Hint : ''
      },
      Name: {
        Label: 'Όνομα',
        Hint : ''
      },
      Description: {
        Label: 'Περιγραφή',
        Hint : ''
      },
      Active: {
        Label: 'Ενεργή',
        Hint : ''
      }
    }
  }

}

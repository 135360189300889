class RouteMiddleware {
  /**
   *   Basic Usage:
   *   Set middleware meta key to your route and add our component to any vue router guard hook.
   *
   *   import RouteMiddleware from 'vue-route-middleware';
   *   ...
   *   const Router = new VueRouter({
   *    mode: 'history',
   *    routes: [{
   *        path: '/dashboard',
   *        name: 'Dashboard',
   *        component: Dashboard,
   *        meta: {
   *            middleware: (to, from, next) => {
   *                let auth = fakeUser.isLogged();
   *                if(!auth){
   *                    next({ name: 'Login' });
   *                }
   *            }
   *        }
   *    }]
   *   });
   *   ...
   *   Router.beforeEach(RouteMiddleware());
   *   NOTICE: Middleware function will retrieve all the variables normally passed to the router guards
   *   Example: (to, from, next) in case of beforeEach or (to, from) in case of afterEach guard.
   *
   *   Chain middlewares with an array of functions:
   *   Example:
   *   middleware: [
   *   (to, from, next) => {
   *        let auth = fakeUser.isLogged();
   *        if(!auth){
   *            next({ name: 'Login' });
   *            return false; // if false is returned, middleware chain will break!
   *        }
   *    },
   *   (to, from, next) => {
   *        let hasPaied = fakeUser.madePayment();
   *        if(!hasPaied){
   *            next({ name: 'Payment' });
   *        }
   *    }
   *   ]
   *   NOTICE: If function returns false then the middleware chain will break and no longer execute.
   *
   *   Separated middleware file example:
   *   ./route/middleware/auth.js
   *   export default (to, from, next) => {
   *    let auth = fakeUser.isLogged();
   *    if(!auth){
   *        next({ name: 'Login' });
   *        return false;
   *    }
   *  }
   *   router.js
   *   import AuthMiddleware from './route/middleware/auth';
   *   ...
   *   meta: {
   *    middleware: [ AuthMiddleware ]
   *  }
   *   ...
   *   Advanced:
   *   Another way to define middlewares is to pass them in the object as the first parameter to
   *   RouteMiddleware function and pass an array of middleware key names to the meta of the route.
   *
   *   Example:
   *   import AuthMiddleware from './route/middleware/auth';
   *   import PaymentMiddleware from './route/middleware/payment';
   *   ...
   *   meta: {
   *    middleware: [ 'AuthMiddleware', 'PaymentMiddleware' ]
   *  }
   *   ...
   *   Router.beforeEach(RouteMiddleware({ AuthMiddleware, PaymentMiddleware }));
   *   This way we can differentiate middlewares that will be applied with different guards.
   *   For example you want to add tracking middleware to afterEach guard:
   *
   *   Example:
   *   import AuthMiddleware from './route/middleware/auth';
   *   import PaymentMiddleware from './route/middleware/payment';
   *   import TrackingMiddleware from './route/middleware/tracking';
   *   ...
   *   meta: {
   *    // define all applied middleware to the route
   *    middleware: [ 'AuthMiddleware', 'PaymentMiddleware', 'TrackingMiddleware' ]
   *  }
   *   ...
   *   Router.beforeEach(RouteMiddleware({ AuthMiddleware, PaymentMiddleware }));
   *   // Pass the tracking function to afterEach guard
   *   Router.afterEach(RouteMiddleware({ TrackingMiddleware }));
   *   At the example above beforeEach guard will apply chained AuthMiddleware and PaymentMiddleware ignoring the TrackingMiddleware that will be applied on afterEach guard.
   **/

  /**
   * Set instance properties and call defined
   * middlewares on matching routes
   *
   * @param {object} definedMiddlewares
   * @param {boolean} applyGlobal
   * @param {VueInstance || object} injection
   * @param {object} to
   * @param {object} from
   * @param {function|undefined} next
   *
   * @var {object} definedMiddlewares // predefined middlewares
   * @var {boolean} applyGlobal // boolean is middleware should be applied globally
   * @var {VueInstance || object} injection // current vue instance or any user defined injected object
   * @var {boolean} nextHasCalled // if next was called in the middlewares
   * @var {array} toMiddleware // arguments passed to middleware function
   */
  constructor (definedMiddlewares, applyGlobal = false, injection, to, from, next) {
    if (this._isObject(definedMiddlewares)) {
      this.middlewares = definedMiddlewares
    } else {
      this._error('Defined middlewares must be of type Object!')
      this.middlewares = {}
    }
    this.applyGlobal = applyGlobal || false
    this.injection = injection || undefined
    this.to = to
    this.from = from
    this.next = next
    this.nextHasCalled = false
    if (this.to && this.to.matched) { // Apply middleware if a route matched
      to.matched.every(route => this.applyRouteMiddlewares(route))
    }
    if (this.next && !this.nextHasCalled) { // call next if user didn't call it
      this.callNext()
    }
  }

  /**
   * Function used to pass arguments to middlewares with spred syntax
   *
   * @return {array}
   */
  toMiddleware () {
    return [
      this.to,
      this.from,
      this._isFunction(this.next) ? this.callNext.bind(this) : undefined,
      this.injection
    ]
  }

  /**
   * Function that is passed to middleware as a next function wrapper
   * toggling `nextHasCalled` trigger
   *
   * @param  {...any} args
   */
  callNext (...args) {
    if (!this.nextHasCalled) this.nextHasCalled = true
    return this.next(...args)
  }

  /**
   * Fuction applying middlewares of a single route and deciding
   * if other matched routes should be checked as well
   *
   * @param {*} route
   *
   * @return {boolean}
   */
  applyRouteMiddlewares (route) {
    if (this.applyGlobal) {
      return Object.keys(this.middlewares).every(middleware => this.applyMiddleware(middleware))
    } else if (route.meta && route.meta.middleware) {
      const middlewareKeys = route.meta.middleware
      if (this._isArray(middlewareKeys)) {
        return middlewareKeys.every(middleware => this.applyMiddleware(middleware))
      } else {
        return this.applyMiddleware(middlewareKeys)
      }
    }

    return true
  }

  /**
   * Function calling middlewares and deciding if middleware chain
   * must be continued or stopped after first faliure
   *
   * @param {string|function} middleware
   *
   * @return {boolean}
   */
  applyMiddleware (middleware) {
    const result = this.getMiddleware(middleware)(...this.toMiddleware())
    return result === undefined ? true : result
  }

  /**
   * Function to get middleware function.
   * In case of function validation failure
   * console the error and return empty function
   *
   * @param {string|function} middleware
   *
   * @return {function}
   */
  getMiddleware (middleware) {
    if (this._isString(middleware)) {
      if (this.middlewares.hasOwnProperty(middleware)) {
        if (this._isFunction(this.middlewares[middleware])) {
          return this.middlewares[middleware]
        } else {
          this._error(middleware + ' is not a function!')
        }
      }
    } else if (this._isFunction(middleware)) {
      return middleware
    } else {
      this._error('All middlewares must be functions!')
    }
    return () => true
  }

  /**
   * @param {string} text
   *
   * @return {boolean}
   */
  _error (text) {
    // eslint-disable-next-line no-console
    console.error(this.constructor.name + ': ' + text)
  }

  /**
   * @param {*} toCheck
   *
   * @return {boolean}
   */
  _isString (toCheck) {
    return typeof toCheck === 'string' || toCheck instanceof String
  }

  /**
   * @param {*} toCheck
   *
   * @return {boolean}
   */
  _isArray (toCheck) {
    return Array.isArray(toCheck)
  }

  /**
   * @param {*} toCheck
   *
   * @return {boolean}
   */
  _isFunction (toCheck) {
    return typeof toCheck === 'function'
  }

  /**
   * @param {*} toCheck
   *
   * @return {boolean}
   */
  _isObject (toCheck) {
    return typeof toCheck === 'object' && toCheck !== null
  }
}

export default (definedGroups = {}, applyGlobal = false, injection = window.Vue || undefined) => {
  return (...toMiddleware) => new RouteMiddleware(definedGroups, applyGlobal, injection, ...toMiddleware)
}

import BaseResource from '@/lib/data/resource/BaseResource'
import { AES }      from '@/lib/crypto/crypto'

export default class ChangePasswordSaveResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.current_password = AES.encrypt(payload.CurrentPassword) ?? null
    this.password = AES.encrypt(payload.Password) ?? null
    this.password_confirmation = AES.encrypt(payload.ConfirmPassword) ?? ''
    this.token = payload.Token ?? ''
    this.email = payload.Email ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}

import BaseResource from '@/lib/data/resource/BaseResource'

export default class PermissionResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.Id ?? null
    this.Name = payload.Name ?? ''
    this.Description = payload.Description ?? ''
    this.Status = payload.Status ?? ''
    this.Action = payload.Action ?? ''
    this.Admin = payload.Admin ?? false
    this.Requires = payload.Requires ?? {
      Parent     : null,
      Permissions: []
    }
  }

  /* PROPERTIES */

  /* METHODS */
}

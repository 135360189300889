import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c(VAppBar,{attrs:{"app":"","clipped-left":"","dark":"","dense":"","flat":""},scopedSlots:_vm._u([(_vm.AppToolbarExtended)?{key:"extension",fn:function(){return [_vm._t("extension-prepend"),_c('portal-target',{attrs:{"name":"AppToolbarExtension","slim":""}}),_vm._t("extension-append")]},proxy:true}:null],null,true)},[(_vm.navigationDrawerVisible !== undefined)?_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();_vm.navigationDrawerVisible = !_vm.navigationDrawerVisible}}}):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c(VToolbarTitle,{staticClass:"body-2 pl-1"},[_vm._t("title",function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]})],2):_vm._e(),_c('div',{staticClass:"flex-grow-1"}),(_vm.items && Array.isArray(_vm.items) && _vm.items.length && _vm.$vuetify.breakpoint.mdAndUp)?_c(VToolbarItems,[_vm._l((_vm.items),function(item,i){return [(item.icon)?_c(VTooltip,{key:("tooltip-" + i),attrs:{"open-delay":650,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({key:("btn-" + i),attrs:{"exact":item.route.exact,"to":{name: item.route.name},"icon":""}},on),[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e(),(item.divider)?_c(VDivider,{key:("divider-" + i),attrs:{"vertical":""}}):_vm._e()]})],2):_vm._e(),_c(VMenu,{attrs:{"close-on-content-click":true,"max-width":"300","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ml-2 pa-2",attrs:{"icon":_vm.$vuetify.breakpoint.smAndDown,"color":"warning","depressed":""}},on),[_c(VAvatar,{staticStyle:{"border":"2px solid #FFF"},attrs:{"size":"30"}},[_c(VIcon,{attrs:{"color":"#FFF"}},[_vm._v(" mdi-account ")])],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.user && _vm.user.DisplayName)+" ")]):_vm._e()],1)]}}]),model:{value:(_vm.userMenuVisible),callback:function ($$v) {_vm.userMenuVisible=$$v},expression:"userMenuVisible"}},[_c(VCard,{attrs:{"tile":""}},[_c(VList,[_c(VListItem,[_c(VListItemAvatar,{staticStyle:{"border":"2px solid"},style:(_vm.$vuetify.theme.dark ? 'border-color: #ffffff;' : 'border-color: #000000;')},[_c(VIcon,{attrs:{"color":_vm.$vuetify.theme.dark ? 'white' : 'black'}},[_vm._v(" mdi-account ")])],1),(_vm.user)?_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.user.FullName))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.user.CompanyName))])],1):_vm._e()],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red accent-4","dark":"","depressed":"","small":""},on:{"click":_vm.logout}},[_c(VIcon,{staticClass:"pr-2"},[_vm._v(" mdi-logout ")]),_vm._v(" "+_vm._s(_vm.$t('Common.Button.Logout'))+" ")],1)],1)],1)],1),(_vm.logoVisible)?_c('div',{staticClass:"d-flex align-center fill-height hidden-sm-and-down"},[_c(VDivider,{staticClass:"mx-2",attrs:{"vertical":""}}),_c('img',{attrs:{"height":"35","src":"/img/logo/logo-dark.png"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
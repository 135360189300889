import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VMain,{staticClass:"grey darken-3"},[_c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c(VCard,{staticClass:"elevation-12 d-inline-block",attrs:{"dark":"","min-width":"280","width":"650"}},[_vm._t("default")],2)],1)],1)],1)],1),_c('app-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }
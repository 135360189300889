import RoleResource from '@/api/resources/role/RoleResource'

export default class RoleSaveResource extends RoleResource {
  constructor (payload = {}) {
    super(payload)

    this.Permissions = this.#parsePermissions(payload.Permissions) ?? {}
  }

  /* PROPERTIES */

  /* METHODS */
  #parsePermissions (permissions) {
    const permissionsObj = {}

    if (!Array.isArray(permissions)) return permissionsObj

    for (let i = 0; i < permissions.length; i++) {
      if (permissions[i].RolePermissions.length) permissionsObj[permissions[i].Id] = permissions[i].RolePermissions
    }

    return permissionsObj
  }
}

export default {

  Title: 'Ρόλοι Χρηστών',

  Table: {
    Button: {
      New: 'Νέος Ρόλος Χρηστών'
    },

    Headers: {
      Id         : '#',
      Name       : 'ΟΝΟΜΑ',
      Description: 'ΠΕΡΙΓΡΑΦΗ',
      Permissions: 'ΔΙΚΑΙΩΜΑΤΑ',
      Admin      : 'ΔΙΑΧΕΙΡΙΣΤΗΣ',
      UsersCount : 'ΧΡΗΣΤΕΣ',
      Color      : 'ΧΡΩΜΑ',
      Active     : 'ΕΝΕΡΓΟΣ',
      Actions    : ''
    },

    Filter: {
      Admin: {
        Type       : 'v-btn-toggle',
        Label      : 'Διαχειριστές',
        PlaceHolder: 'Όλοι οι Ρόλοι Χρηστών',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: 'null',
            Name : 'Όλοι'
          },
          {
            Value: 1,
            Name : 'Ενεργοί'
          },
          {
            Value: 0,
            Name : 'Ανενεργοί'
          }
        ]
      },

      CompanyId: {
        Type       : 'v-select',
        Label      : 'Εταιρείες',
        PlaceHolder: 'Όλες οι Εταιρείες',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      }
    }
  },

  Confirm: {
    Delete: {
      Title   : 'Διαγραφή Ρόλου Χρηστών {Id}',
      SubTitle: 'Επιβεβαίωση Διαγραφής Ρόλου Χρηστών',
      Body    : 'Είστε σίγουροι ότι θέλετε προχωρήσετε στην οριστική διαγραφή του Ρόλου Χρηστών {Id};'
    }
  },

  Form: {
    Title : 'Ρόλου Χρηστών',
    Fields: {
      Name: {
        Label: 'Όνομα',
        Hint : ''
      },
      Description: {
        Label: 'Περιγραφή',
        Hint : ''
      },
      Permissions: {
        Label: 'Δικαιώματα',
        Hint : ''
      },
      Admin: {
        Label: 'Διαχειριστής',
        Hint : ''
      },
      Color: {
        Label: 'Χρώμα',
        Hint : ''
      },
      CompanyId: {
        Label: 'Εταιρεία',
        Hint : ''
      },
      Active: {
        Label: 'Ενεργός',
        Hint : ''
      }
    }
  },

  Permissions: {
    Title  : 'Δικαιώματα Ρόλου',
    Headers: {
      Right  : 'Δικαίωμα',
      Api    : 'Api',
      Access : 'Πρόσβαση',
      Manage : 'Διαχείριση',
      Add    : 'Προσθήκη',
      Delete : 'Διαγραφή',
      Toolbar: 'Γραμμή Εργαλείων',
      Menu   : 'Μενού'
    }
  }

}

const PermissionEnum = {
  API    : 'API',
  ACCESS : 'ACCESS',
  ADD    : 'ADD',
  DELETE : 'DELETE',
  MANAGE : 'MANAGE',
  MENU   : 'MENU',
  TOOLBAR: 'TOOLBAR'
}

export default PermissionEnum

import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"color":_vm.cardColor,"elevation":_vm.elevation,"flat":_vm.flat,"tile":_vm.tile}},[_c(VToolbar,{attrs:{"color":_vm.coloredBorder ? '' : _vm.color,"dark":_vm.coloredBorder ? undefined: _vm.dark,"dense":_vm.dense,"extended":_vm.toolbarExtended,"height":_vm.toolbarHeight,"max-height":_vm.toolbarExtended ? parseInt(_vm.toolbarHeight) + 48 : parseInt(_vm.toolbarHeight),"tile":_vm.tile,"flat":""},scopedSlots:_vm._u([(_vm.toolbarExtended)?{key:"extension",fn:function(){return [_vm._t("toolbar-extension")]},proxy:true}:null],null,true)},[_c('div',{staticClass:"v-alert__border v-alert__border--left",class:[_vm.coloredBorder ? (_vm.color + "--text v-alert__border--has-color") : '']}),_vm._t("toolbar-avatar",function(){return [(_vm.icon)?_c(VAvatar,{staticClass:"mr-2",attrs:{"color":_vm.coloredBorder ? (_vm.color + " lighten-3") : (_vm.color + " lighten-1"),"size":"36"}},[_c(VIcon,{attrs:{"color":_vm.coloredBorder ? ("" + _vm.color) : ''}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1):_vm._e()]},{"data":{icon: _vm.icon, color: _vm.color, coloredBorder: _vm.coloredBorder}}),(_vm.divider)?_c(VDivider,{staticClass:"mr-2",attrs:{"vertical":""}}):_vm._e(),(_vm.title || _vm.subTitle)?_c(VToolbarTitle,[(_vm.title)?_c('div',{staticClass:"subtitle-1",class:[_vm.coloredBorder ? (_vm.color + "--text") : '']},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.subTitle)?_c('div',{staticClass:"caption",class:[_vm.coloredBorder ? 'grey--text text--darken-1' : (_vm.color + "--text text--lighten-4")]},[_vm._v(" "+_vm._s(_vm.subTitle)+" ")]):_vm._e()]):_vm._e(),_c(VSpacer),_vm._t("toolbar-append"),_c(VProgressLinear,{attrs:{"active":_vm.loading,"color":(_vm.color + " darken-3"),"indeterminate":_vm.loading,"absolute":"","bottom":"","height":"2"}})],2),_c(VDivider),_vm._t("default",function(){return [_c(VCardText)]}),_vm._t("card-actions")],2)}
var staticRenderFns = []

export { render, staticRenderFns }
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"fullscreen":"","persistent":"","scrollable":""},model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[(_vm.model)?_c('data-card',{attrs:{"color":_vm.$t("Route.Permissions.Color"),"colored-border":true,"icon":_vm.$t("Route.Permissions.Icon"),"sub-title":((_vm.$t((_vm.parentComponentName + ".Permissions.Title"))) + " '" + (_vm.model.Name) + "'"),"title":((_vm.model.Id ? _vm.isReadOnly ? _vm.$t('Common.Button.View') : _vm.$t('Common.Button.Edit') : _vm.$t('Common.Button.Create')) + " " + (_vm.$t((_vm.parentComponentName + ".Form.Title"))))},scopedSlots:_vm._u([{key:"toolbar-append",fn:function(){return [_c(VBtn,{attrs:{"disabled":_vm.isFormSaving,"icon":""},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)]},proxy:true},{key:"default",fn:function(){return [_c(VCardText,{ref:"formContainer",staticClass:"pt-5"},[_c(VForm,{ref:"form",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.model.Permissions,"items-per-page":-1,"fixed-header":"","height":"calc(100vh - 152px - 16px)","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.Permission",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Permission)+" ")]}},(_vm.userIsSuperAdmin)?{key:"item.Api",fn:function(ref){
var item = ref.item;
return [(item.Action.includes(_vm.PermissionEnum.API.toLowerCase()))?_c(VCheckbox,{staticClass:"d-inline-block",attrs:{"color":_vm.getPermissionColor(_vm.PermissionEnum.API.toLowerCase()),"value":_vm.PermissionEnum.API.toLowerCase(),"hide-details":"","primary":"","readonly":""},on:{"change":function($event){_vm.onCheckboxChange(_vm.PermissionEnum.API.toLowerCase(), item)}},model:{value:(item.RolePermissions),callback:function ($$v) {_vm.$set(item, "RolePermissions", $$v)},expression:"item.RolePermissions"}}):_vm._e()]}}:null,{key:"item.Access",fn:function(ref){
var item = ref.item;
return [(item.Action.includes(_vm.PermissionEnum.ACCESS.toLowerCase()))?_c(VCheckbox,{staticClass:"d-inline-block",attrs:{"color":_vm.getPermissionColor(_vm.PermissionEnum.ACCESS.toLowerCase()),"disabled":!_vm.userCanManage,"value":_vm.PermissionEnum.ACCESS.toLowerCase(),"hide-details":"","primary":""},on:{"change":function($event){_vm.onCheckboxChange(_vm.PermissionEnum.ACCESS.toLowerCase(), item)}},model:{value:(item.RolePermissions),callback:function ($$v) {_vm.$set(item, "RolePermissions", $$v)},expression:"item.RolePermissions"}}):_vm._e()]}},{key:"item.Manage",fn:function(ref){
var item = ref.item;
return [(item.Action.includes(_vm.PermissionEnum.MANAGE.toLowerCase()))?_c(VCheckbox,{staticClass:"d-inline-block",attrs:{"color":_vm.getPermissionColor(_vm.PermissionEnum.MANAGE.toLowerCase()),"disabled":!_vm.userCanManage,"value":_vm.PermissionEnum.MANAGE.toLowerCase(),"hide-details":"","primary":""},on:{"change":function($event){_vm.onCheckboxChange(_vm.PermissionEnum.MANAGE.toLowerCase(), item)}},model:{value:(item.RolePermissions),callback:function ($$v) {_vm.$set(item, "RolePermissions", $$v)},expression:"item.RolePermissions"}}):_vm._e()]}},{key:"item.Add",fn:function(ref){
var item = ref.item;
return [(item.Action.includes(_vm.PermissionEnum.ADD.toLowerCase()))?_c(VCheckbox,{staticClass:"d-inline-block",attrs:{"color":_vm.getPermissionColor(_vm.PermissionEnum.ADD.toLowerCase()),"disabled":!_vm.userCanManage,"value":_vm.PermissionEnum.ADD.toLowerCase(),"hide-details":"","primary":""},on:{"change":function($event){_vm.onCheckboxChange(_vm.PermissionEnum.ADD.toLowerCase(), item)}},model:{value:(item.RolePermissions),callback:function ($$v) {_vm.$set(item, "RolePermissions", $$v)},expression:"item.RolePermissions"}}):_vm._e()]}},{key:"item.Delete",fn:function(ref){
var item = ref.item;
return [(item.Action.includes(_vm.PermissionEnum.DELETE.toLowerCase()))?_c(VCheckbox,{staticClass:"d-inline-block",attrs:{"color":_vm.getPermissionColor(_vm.PermissionEnum.DELETE.toLowerCase()),"disabled":!_vm.userCanManage,"value":_vm.PermissionEnum.DELETE.toLowerCase(),"hide-details":"","primary":""},on:{"change":function($event){_vm.onCheckboxChange(_vm.PermissionEnum.DELETE.toLowerCase(), item)}},model:{value:(item.RolePermissions),callback:function ($$v) {_vm.$set(item, "RolePermissions", $$v)},expression:"item.RolePermissions"}}):_vm._e()]}},(_vm.userIsSuperAdmin)?{key:"item.Toolbar",fn:function(ref){
var item = ref.item;
return [(item.Action.includes(_vm.PermissionEnum.TOOLBAR.toLowerCase()))?_c(VCheckbox,{staticClass:"d-inline-block",attrs:{"color":_vm.getPermissionColor(_vm.PermissionEnum.TOOLBAR.toLowerCase()),"disabled":!_vm.userCanManage,"value":_vm.PermissionEnum.TOOLBAR.toLowerCase(),"hide-details":"","primary":""},on:{"change":function($event){_vm.onCheckboxChange(_vm.PermissionEnum.TOOLBAR.toLowerCase(), item)}},model:{value:(item.RolePermissions),callback:function ($$v) {_vm.$set(item, "RolePermissions", $$v)},expression:"item.RolePermissions"}}):_vm._e()]}}:null,(_vm.userIsSuperAdmin)?{key:"item.Menu",fn:function(ref){
var item = ref.item;
return [(item.Action.includes(_vm.PermissionEnum.MENU.toLowerCase()))?_c(VCheckbox,{staticClass:"d-inline-block",attrs:{"color":_vm.getPermissionColor(_vm.PermissionEnum.MENU.toLowerCase()),"disabled":!_vm.userCanManage,"value":_vm.PermissionEnum.MENU.toLowerCase(),"hide-details":"","primary":""},on:{"change":function($event){_vm.onCheckboxChange(_vm.PermissionEnum.MENU.toLowerCase(), item)}},model:{value:(item.RolePermissions),callback:function ($$v) {_vm.$set(item, "RolePermissions", $$v)},expression:"item.RolePermissions"}}):_vm._e()]}}:null,(_vm.userIsSuperAdmin && _vm.userCanDelete)?{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [(_vm.userCanDelete)?_c(VTooltip,{attrs:{"open-delay":650,"bottom":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.userCanDelete)?_c(VBtn,_vm._g({staticClass:"ml-1 white--text",attrs:{"color":"red","depressed":"","fab":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},on),[_c(VIcon,[_vm._v("mdi-close")])],1):_vm._e()]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('Common.Button.Delete'))}})]):_vm._e()]}}:null],null,true)})],1)],1)]},proxy:true},{key:"card-actions",fn:function(){return [_c(VAlert,{staticClass:"ma-0 pt-4 text-center",attrs:{"value":!!_vm.showGenericError,"border":"top","colored-border":"","dense":"","tile":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.isBoolean(_vm.showGenericError) ? _vm.$t('Common.Error.Generic') : _vm.showGenericError)+" ")]),_c(VDivider),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"disabled":_vm.isFormSaving,"large":"","plain":"","tile":""},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.isReadOnly ? _vm.$t('Common.Button.Close') : _vm.$t('Common.Button.Cancel'))+" ")]),(!_vm.isReadOnly)?_c(VBtn,{attrs:{"disabled":_vm.isFormSaving,"loading":_vm.isFormSaving,"color":"success","depressed":""},domProps:{"textContent":_vm._s(_vm.$t('Common.Button.Save'))},on:{"click":_vm.saveFromData}}):_vm._e()],1)]},proxy:true}],null,false,1610548260)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
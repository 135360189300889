import dayjs             from 'dayjs'
import relativeTime      from 'dayjs/plugin/relativeTime'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween         from 'dayjs/plugin/isBetween'
import isSameOrBefore    from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter     from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(isBetween)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)

const DayjsPlugin = {
  install (Vue) {
    Object.defineProperties(Vue.prototype, {
      $dayjs: {
        get () {
          return dayjs
        }
      }
    })
    Vue.dayjs = dayjs
  }
}

export default DayjsPlugin

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(DayjsPlugin)
}

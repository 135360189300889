<template>
  <div class="">
    <v-app-bar
      app
      clipped-left
      dark
      dense
      flat
    >
      <v-app-bar-nav-icon
        v-if="navigationDrawerVisible !== undefined"
        @click.stop="navigationDrawerVisible = !navigationDrawerVisible"
      />

      <v-toolbar-title
        v-if="$vuetify.breakpoint.mdAndUp"
        class="body-2 pl-1"
      >
        <slot name="title">
          {{ title }}
        </slot>
      </v-toolbar-title>

      <div class="flex-grow-1" />

      <v-toolbar-items v-if="items && Array.isArray(items) && items.length && $vuetify.breakpoint.mdAndUp">
        <template v-for="(item, i) in items">
          <v-tooltip
            v-if="item.icon"
            :key="`tooltip-${i}`"
            :open-delay="650"
            bottom
          >
            <template #activator="{on}">
              <v-btn
                :key="`btn-${i}`"
                :exact="item.route.exact"
                :to="{name: item.route.name}"
                icon
                v-on="on"
              >
                <v-icon>{{ item.icon }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.text }}</span>
          </v-tooltip>

          <v-divider
            v-if="item.divider"
            :key="`divider-${i}`"
            vertical
          />
        </template>
      </v-toolbar-items>

      <v-menu
        v-model="userMenuVisible"
        :close-on-content-click="true"
        max-width="300"
        offset-y
      >
        <template #activator="{on}">
          <v-btn
            :icon="$vuetify.breakpoint.smAndDown"
            class="ml-2 pa-2"
            color="warning"
            depressed
            v-on="on"
          >
            <v-avatar
              size="30"
              style="border: 2px solid #FFF"
            >
              <v-icon color="#FFF">
                mdi-account
              </v-icon>
            </v-avatar>

            <span
              v-if="$vuetify.breakpoint.mdAndUp"
              class="ml-2"
            >
              {{ user && user.DisplayName }}
            </span>
          </v-btn>
        </template>

        <v-card tile>
          <v-list>
            <v-list-item>
              <v-list-item-avatar
                :style="$vuetify.theme.dark ? 'border-color: #ffffff;' : 'border-color: #000000;'"
                style="border: 2px solid;"
              >
                <v-icon :color="$vuetify.theme.dark ? 'white' : 'black'">
                  mdi-account
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content v-if="user">
                <v-list-item-title>{{ user.FullName }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.CompanyName }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider />

          <v-card-actions>
            <!--
            <v-btn
              small
              text
              @click="getUserProfile"
            >
              <v-icon>
                mdi-account-edit
              </v-icon>
              <span class="pl-2">{{ $t('Common.Button.Edit') }}</span>
            </v-btn>
            -->

            <v-spacer />

            <v-btn
              color="red accent-4"
              dark
              depressed
              small
              @click="logout"
            >
              <v-icon
                class="pr-2"
              >
                mdi-logout
              </v-icon>
              {{ $t('Common.Button.Logout') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <div
        v-if="logoVisible"
        class="d-flex align-center fill-height hidden-sm-and-down"
      >
        <v-divider
          class="mx-2"
          vertical
        />

        <img
          height="35"
          src="/img/logo/logo-dark.png"
        >
      </div>

      <template
        v-if="AppToolbarExtended"
        #extension
      >
        <slot name="extension-prepend" />
        <portal-target
          name="AppToolbarExtension"
          slim
        />
        <slot name="extension-append" />
      </template>
    </v-app-bar>
  </div>
</template>

<script>

import * as HelperFunctions from '@/lib/helpers'

export default {
  name      : 'AppToolbar',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    items: {
      type    : Array,
      required: true
    },
    navigationDrawer: {
      type   : Boolean,
      default: undefined
    },
    logoVisible: {
      type   : Boolean,
      default: true
    },
    langSwitch: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: { AppToolbarExtended: 'App.Config.AppToolbarExtended' },
  data () {
    return {
      userMenuVisible: false
    }
  },
  computed: {
    title () {
      const key = `Route.${ this.$route.name }.Title`
      return this.$te(key) ? this.$t(key) : this.$t('Common.App.Title')
    },

    navigationDrawerVisible: {
      get () {
        return this.navigationDrawer
      },
      set (val) {
        this.$emit('update:navigationDrawer', val)
      }
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    ...HelperFunctions,

    getUserProfile () {

    }
  }
}
</script>

<style scoped>
/deep/ .v-toolbar__content {
  padding-top    : 0;
  padding-bottom : 0;
  height         : 100% !important;
}
</style>

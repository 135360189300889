<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800px"
      persistent
      scrollable
    >
      <data-card
        v-if="model"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :title="`${model.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)}`"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isFormSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="isFormValid"
            >
              <v-container fluid>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="model.Id"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Id']"
                      :hint="$te(`${parentComponentName}.Form.Fields.Id.Hint`) ? $t(`${parentComponentName}.Form.Fields.Id.Hint`) : ''"
                      :label="$te(`${parentComponentName}.Form.Fields.Id.Label`) ? $t(`${parentComponentName}.Form.Fields.Id.Label`) : 'Name'"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Id')"
                      prepend-inner-icon="mdi-pound-box-outline"
                    />
                  </v-col>

                  <v-col cols="8">
                    <v-text-field
                      v-model="model.Name"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Name']"
                      :hint="$te(`${parentComponentName}.Form.Fields.Name.Hint`) ? $t(`${parentComponentName}.Form.Fields.Name.Hint`) : ''"
                      :label="$te(`${parentComponentName}.Form.Fields.Name.Label`) ? $t(`${parentComponentName}.Form.Fields.Name.Label`) : 'Name'"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Name')"
                      prepend-inner-icon="mdi-tag-text-outline"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="model.Description"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Description']"
                      :hint="$te(`${parentComponentName}.Form.Fields.Description.Hint`) ? $t(`${parentComponentName}.Form.Fields.Description.Hint`) : ''"
                      :label="$te(`${parentComponentName}.Form.Fields.Description.Label`) ? $t(`${parentComponentName}.Form.Fields.Description.Label`) : 'Description'"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Description')"
                      prepend-inner-icon="mdi-subtitles-outline"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="model.Requires.Parent"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :items="permissionsDropDownList"
                      :label="$te(`${parentComponentName}.Form.Fields.Parent.Label`) ? $t(`${parentComponentName}.Form.Fields.Parent.Label`) : 'Parent'"
                      :menu-props="{maxHeight: '250'}"
                      :readonly="isReadOnly"
                      item-text="Name"
                      item-value="Id"
                      prepend-inner-icon="mdi-call-merge"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="model.Action"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Action']"
                      :items="permissionActions"
                      :label="$te(`${parentComponentName}.Form.Fields.Action.Label`) ? $t(`${parentComponentName}.Form.Fields.Action.Label`) : 'Action'"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Action')"
                      item-text="Name"
                      item-value="Id"
                      multiple
                      prepend-inner-icon="mdi-list-status"
                      small-chips
                    >
                      <template #selection="{item}">
                        <v-chip
                          :color="$parent.getPermissionColor(item.Name)"
                          class="justify-center mr-1"
                          label
                          small
                          style="min-width: 75px;"
                          text-color="white"
                        >
                          {{ item.Name.toLocaleUpperCase($i18n.locale) }}
                        </v-chip>
                      </template>

                      <template #item="{on, attrs, item}">
                        <v-list-item
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-list-item-action>
                            <v-checkbox :value="attrs.inputValue" />
                          </v-list-item-action>

                          <v-list-item-content>
                            <v-list-item-title>
                              <v-chip
                                :color="$parent.getPermissionColor(item.Name)"
                                class="justify-center mr-1"
                                label
                                small
                                style="min-width: 75px;"
                                text-color="white"
                              >
                                {{ item.Name.toLocaleUpperCase($i18n.locale) }}
                              </v-chip>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="model.Requires.Permissions"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Action']"
                      :items="permissionsDropDownList"
                      :label="$te(`${parentComponentName}.Form.Fields.Requires.Label`) ? $t(`${parentComponentName}.Form.Fields.Requires.Label`) : 'Required Permissions'"
                      :menu-props="{maxHeight: '250'}"
                      :readonly="isReadOnly"
                      item-text="Name"
                      item-value="Id"
                      multiple
                      prepend-inner-icon="mdi-lock-check-outline"
                      small-chips
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <label class="grey--text caption">
                      {{ $t(`${ parentComponentName }.Form.Fields.Status.Label`) }}
                    </label>
                    <v-switch
                      v-model="model.Status"
                      :disabled="isReadOnly"
                      :label="model.Status ? $t(`Common.Button.Toggle.Active`) : $t(`Common.Button.Toggle.Inactive`)"
                      :readonly="isReadOnly"
                      class="mt-1"
                      color="success"
                      inset
                    />
                  </v-col>

                  <v-col
                    v-if="userIsSuperAdmin"
                    cols="6"
                  >
                    <label class="grey--text caption">
                      {{ $t(`${ parentComponentName }.Form.Fields.Admin.Label`) }}
                    </label>
                    <v-switch
                      v-model="model.Admin"
                      :disabled="isReadOnly"
                      :label="model.Admin ? $t(`Common.Button.Toggle.Active`) : $t(`Common.Button.Toggle.Inactive`)"
                      :readonly="isReadOnly"
                      class="mt-1"
                      color="success"
                      inset
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="!!showGenericError"
            border="top"
            class="ma-0 pt-4 text-center"
            colored-border
            dense
            tile
            type="error"
          >
            {{ isBoolean(showGenericError) ? $t('Common.Error.Generic') : showGenericError }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />

            <v-btn
              :disabled="isFormSaving"
              large
              plain
              tile
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>

            <v-btn
              v-if="!isReadOnly"
              :disabled="isFormSaving"
              :loading="isFormSaving"
              color="success"
              depressed
              @click="saveFromData"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { themeUtils }  from '@/lib/utils'
import { isBoolean }   from '@/lib/utils/type'
import dialogVisible   from '@/mixins/dialog/dialogVisible'
import dialogData      from '@/mixins/dialog/dialogData'
import PermissionModel from '@/api/models/permission/PermissionModel'
import API             from '@/api/Api'
import { collect }     from '@/lib/utils/array'
import PermissionEnum  from '@/api/enums/PermissionEnum'

const DataCard = () => themeUtils.importThemeComponent('components/common/DataCard')

export default {
  name      : 'PermissionEditDialog',
  components: { DataCard },
  directives: {},
  mixins    : [dialogVisible, dialogData],
  props     : {},
  enums     : {},
  dataModel : PermissionModel,
  data () {
    return {
      permissionsDropDownList: []
    }
  },
  computed: {
    permissionActions () {
      return Object.values(PermissionEnum).map(p => {
        return {
          Id  : p.toLowerCase(),
          Name: p.toUpperCase()
        }
      })
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) this.getPermissionDropDownList()
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    ...{ isBoolean },

    getPermissionDropDownList () {
      API.Resource.Permission.DropDownList()
        .then(response => {
          if (API.isResponseSuccess(response)) {
            this.permissionsDropDownList = collect(response.data?.data?.items || [], PermissionModel)
          }
        })
        .catch(e => { })
        .finally(() => { })
    }
  }
}
</script>

<style scoped>

</style>

import BaseResource from '@/lib/data/resource/BaseResource'
import { isArray }  from '@/lib/utils/type'
import { clone }    from '@/lib/utils/helper'

export default class BaseModel {
  #validator = null
  #guarded = []
  #showWarnings = false

  /* PROPERTIES */

  /**
   * Create a new BaseModel
   *
   * @param {Object | BaseResource} [Payload={}] the object or resource to model.
   * @param {BaseResource} [Resource=null] the resource class to refactor model data.
   * @param {BaseValidator} [Validator=null] the validator class to validate model data.
   *
   * @return {BaseModel} A BaseModel object.
   */
  constructor (Payload = {}, Resource = null, Validator = null) {
    this.guarded = []
    this.setData(Payload || {}, Resource)
    this.#validator = Validator ? new Validator(this) : null
    this.boot()
    return this
  }

  /**
   * Get the validator used to validate properties.
   *
   * @returns {BaseValidator}
   */
  get validator () {
    return this.#validator
  }

  /**
   * Sets the validator to be used to validate properties.
   *
   * @param val {BaseValidator}
   */
  set validator (val) {
    this.#validator = val
  }

  /**
   * Get the validator errors object
   *
   * @returns {Object}
   */
  get errors () {
    let validatorsFound = 0
    let errors = {}
    Object.keys(this).forEach((key) => {
      if (this[key] instanceof BaseModel) {
        if (this[key].validator) {
          validatorsFound++
          const tmp = {}
          tmp[key] = this[key].errors
          if (Object.keys(tmp[key]).length > 0) errors = Object.assign({}, errors, tmp)
        }
      } else if (isArray(this[key])) {
        const tmp = {}
        this[key].forEach((item, index) => {
          if (item instanceof BaseModel) {
            if (item.validator) {
              validatorsFound++
              if (Object.keys(item.errors ?? {}).length > 0) tmp[index] = item.errors
              if (Object.keys(tmp[index] ?? {}).length > 0) errors[key] = tmp
            }
          }
        })
      }
    })
    if (this.validator) {
      validatorsFound++
      if (Object.keys(this.validator.errors).length > 0) errors = Object.assign({}, errors, this.validator.errors)
    }

    if (!validatorsFound && this.#showWarnings) {
      /* eslint-disable-next-line no-console */
      console.warn(`Model:${ this.constructor.name } has no validator set and it doesn't have any model property with a validator.`)
    }

    return errors
  }

  /**
   * Get guarded properties array
   *
   * @returns {Array}
   */
  get guarded () {
    return this.#guarded || []
  }

  /**
   * The guarded property should contain an array of attributes that you do not want to be
   * serialized during {clone} or {toString}. All other attributes not in the array will be
   * serialized.
   *
   * @param {Array} guardedPropertiesArray
   */
  set guarded (guardedPropertiesArray) {
    this.#guarded = isArray(guardedPropertiesArray) ? guardedPropertiesArray : []
  }

  /* METHODS */
  /**
   * Called after construction, this hook allows you to add some extra setup
   * logic without having to override the constructor.
   */
  boot () {

  }

  /**
   * Sets the data to the model
   *
   * @param {BaseResource} [Resource=null] the resource class to refactor model data.
   * @param {Object | BaseResource} [Payload={}] the object or resource to model.
   */
  setData (Payload = {}, Resource = null) {
    if (this.constructor.name === BaseModel.prototype.constructor.name || Payload instanceof BaseResource || Payload instanceof Object) {
      const data = Resource ? new Resource(Payload) : Payload
      Object.assign(this, data)

      const props = Object.getOwnPropertyDescriptors(data.constructor.prototype) || {}
      Object.keys(props).forEach(key => {
        if (typeof props[key].set === 'function' || typeof props[key].get === 'function') {
          // console.log({ [key]: props[key] }, data[key])
          this.definePrivateProperty(key, data[key])
          props[key].enumerable = true
          Object.defineProperty(this, key, props[key])
        }
      })
    }
  }

  /**
   * Defines a private non enumerable property on an object
   *
   * @param {String} propName the property name
   * @param {any} propValue the propert value
   */
  definePrivateProperty (propName, propValue = null) {
    if (!propName) return
    Object.defineProperty(this, `_${ propName }`, {
      enumerable  : false,
      configurable: false,
      writable    : true,
      value       : propValue
    })
  }

  /**
   * Reset model data
   */
  reset () {
    if (this.constructor.name === BaseModel.prototype.constructor.name) {
      Object.keys(this).forEach(key => delete this[key])
    }
    this.setData()
  }

  /**
   * Validate model data with model validator and nested models with their validators
   *
   * @returns {boolean}
   */
  validate () {
    let isValid = 0
    let validatorsFound = 0

    Object.keys(this).forEach(key => {
      if (this[key] instanceof BaseModel) {
        if (this[key].validator) {
          validatorsFound++
          if (!this[key].validate()) isValid++
        }
      } else if (isArray(this[key])) {
        this[key].forEach(item => {
          if (item instanceof BaseModel) {
            if (item.validator) {
              validatorsFound++
              if (!item.validate()) isValid++
            }
          }
        })
      }
    })

    if (this.validator) {
      validatorsFound++
      if (!this.validator.validate()) isValid++
    }

    if (!validatorsFound && this.#showWarnings) {
      /* eslint-disable-next-line no-console */
      console.warn(`Model:${ this.constructor.name } has no validator set and it doesn't have any model property with a validator.`)
    }

    return isValid <= 0
  }

  /**
   * Validate a specific property/field of the model with the model validator
   *
   * @param property {String}
   *
   * @returns {boolean}
   */
  validateField (property) {
    if (this.validator) {
      return this.validator.validateField(property)
    } else if (this.#showWarnings) {
      /* eslint-disable-next-line no-console */
      console.warn(`Model:${ this.constructor.name } has no validator set and it doesn't have any model property with a validator.`)
    }
  }

  /**
   * Get Vuetify property/field rules array
   *
   * @param field
   *
   * @returns {Array}
   */
  vuetifyFormFieldRules (field) {
    let validatorsFound = 0
    let rules = []
    let model = this

    if (field.includes('.') && this[field.split('.')[0]] instanceof BaseModel) {
      field = field.split('.')
      model = this[field.shift()]
      field = field.join('.')
      validatorsFound++
      const modelFieldRules = model.vuetifyFormFieldRules(field)
      if (modelFieldRules.length > 0) rules = modelFieldRules
      return rules
    }

    if (model.validator) {
      validatorsFound++
      const modelFieldRules = model.validator.vuetifyFormFieldRules(field)
      if (modelFieldRules.length > 0) rules = modelFieldRules
    }

    if (!validatorsFound && this.#showWarnings) {
      /* eslint-disable-next-line no-console */
      console.warn(`Model:${ this.constructor.name } has no validator set and it doesn't have any model property with a validator.`)
    }

    return rules
  }

  /**
   * Gets a serialized version of the model.
   *
   * @param {BaseResource} [Resource=null] Optional resource to manipulate the data
   *
   * @returns {JSON | Object} The serialized model
   */
  clone (includeGuardedFields = false, Resource = null) {
    let retVal = null

    Object.keys(this).forEach((key) => {
      if (this[key] instanceof BaseModel) {
        this[key] = this[key].clone()
      }
    })

    retVal = clone(Resource ? new Resource(this) : this)

    if (!includeGuardedFields) Object.keys(retVal).filter(key => this.#guarded.includes(key)).forEach(key => delete retVal[key])

    return retVal
  }

  /**
   * stringifies the model using JSON.stringify API.
   *
   * @param {BaseResource} [Resource=null] Optional resource to manipulate the data
   * @return {string} The stringified model.
   */
  toString (includeGuardedFields = false, Resource = null) {
    return JSON.stringify(this.clone(includeGuardedFields, Resource))
  }

  /* API METHODS */
}

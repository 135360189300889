import { render, staticRenderFns } from "./RoleEditDialog.vue?vue&type=template&id=3f0accca&scoped=true&"
import script from "./RoleEditDialog.vue?vue&type=script&lang=js&"
export * from "./RoleEditDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f0accca",
  null
  
)

export default component.exports
import utils from './index'

const UtilsPlugin = {
  install (Vue, options = { name: 'utils' }) {
    Object.defineProperty(Vue.prototype, '$' + (options?.name || 'utils'), {
      get () {
        return utils
      }
    })
  }
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(UtilsPlugin)
}

export default UtilsPlugin

import BaseResource from '@/lib/data/resource/BaseResource'

export default class TwoFAResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Pin = payload.Pin ?? ''
    this.Token = payload.Token ?? this.#urlParam.token ?? ''
    this.Username = payload.Username ?? this.#urlParam.username ?? ''
    this.Expire = parseInt(payload.Expire) || parseInt(this.#urlParam.expire) || 60
  }

  /* PROPERTIES */
  get #urlParam () {
    const urlSearchParams = new URLSearchParams(window.location.search ?? '')
    const params = Object.fromEntries(urlSearchParams.entries())
    return params || {}
  }

  /* METHODS */
}

import BaseResource from '@/lib/data/resource/BaseResource'

export default class ChangePasswordResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.CurrentPassword = payload.CurrentPassword ?? null
    this.Password = payload.Password ?? null
    this.ConfirmPassword = payload.ConfirmPassword ?? ''
    this.Token = payload.Token ?? this.#urlParam.token ?? ''
    this.Email = payload.Email ?? this.#urlParam.email ?? ''
  }

  /* PROPERTIES */
  get #urlParam () {
    const urlSearchParams = new URLSearchParams(window.location.search ?? '')
    const params = Object.fromEntries(urlSearchParams.entries())
    return params || {}
  }

  /* METHODS */
}

// Dependencies
import Vue       from 'vue'
import Router    from 'vue-router'
import AppRoutes from './routes'

if (window.location.href.endsWith('//')) {
  window.location.href = window.location.href.replace(/\/+$/, '')
}

Vue.use(Router)

const createRouter = () => new Router({
  mode  : 'history',
  base  : process.env.BASE_URL,
  routes: [],
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        el      : to.hash,
        behavior: 'smooth'
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      if (to.params.tab || to.params.subTab) return
      return {
        x: 0,
        y: 0
      }
    }
  }
})

export const router = createRouter()

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
  window.isVueRoutesInited = false
  const routes = [
    {
      path     : '/:lang',
      props    : true,
      component: {
        render (c) {
          return c('router-view')
        }
      },
      children: AppRoutes.LangRoutes
    },
    ...AppRoutes.SimpleRoutes
  ]

  routes.forEach(route => router.addRoute(route))
}

const routerPush = Router.prototype.push
const routerReplace = Router.prototype.replace
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}
Router.prototype.replace = function replace (location) {
  return routerReplace.call(this, location).catch(error => error)
}

export default router

import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"max-width":800,"persistent":"","scrollable":""},model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[(_vm.model)?_c('data-card',{attrs:{"color":_vm.$t(("Route." + _vm.parentComponentName + ".Color")),"colored-border":true,"icon":_vm.$t(("Route." + _vm.parentComponentName + ".Icon")),"sub-title":!_vm.userIsSuperAdmin ? _vm.model.Description : '',"title":((_vm.$t('Common.Button.Copy')) + " " + (_vm.$t((_vm.parentComponentName + ".CopyDialog.Title"))))},scopedSlots:_vm._u([{key:"toolbar-append",fn:function(){return [_c(VBtn,{attrs:{"disabled":_vm.isFormSaving,"icon":""},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)]},proxy:true},{key:"default",fn:function(){return [_c(VCardText,{ref:"formContainer",staticClass:"pt-5"},[_c(VForm,{ref:"form",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VSelect,{attrs:{"disabled":_vm.isReadOnly,"error-messages":_vm.serverErrorMessages['CompanyIds'],"hint":_vm.$t((_vm.parentComponentName + ".CopyDialog.Form.Fields.CompanyIds.Hint")),"items":_vm.companies,"label":_vm.$t((_vm.parentComponentName + ".CopyDialog.Form.Fields.CompanyIds.Label")),"readonly":_vm.isReadOnly,"rules":_vm.model.validator.vuetifyFormFieldRules('CompanyIds'),"item-text":"Name","item-value":"Id","multiple":"","prepend-inner-icon":"mdi-domain","small-chips":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var item = ref.item;
return [_c(VListItem,_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.Name)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(item.Description)}})],1)],1)]}}],null,false,3600998945),model:{value:(_vm.model.CompanyIds),callback:function ($$v) {_vm.$set(_vm.model, "CompanyIds", $$v)},expression:"model.CompanyIds"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('label',{staticClass:"grey--text caption"},[_vm._v(" "+_vm._s(_vm.$t((_vm.parentComponentName + ".CopyDialog.Form.Fields.ForceUpdate.Label")))+" ")]),_c(VSwitch,{staticClass:"mt-1",attrs:{"disabled":_vm.isReadOnly,"label":_vm.model.ForceUpdate ? _vm.$t("Common.Button.Yes") : _vm.$t("Common.Button.No"),"readonly":_vm.isReadOnly,"color":"success","inset":""},model:{value:(_vm.model.ForceUpdate),callback:function ($$v) {_vm.$set(_vm.model, "ForceUpdate", $$v)},expression:"model.ForceUpdate"}})],1)],1)],1)],1)],1)]},proxy:true},{key:"card-actions",fn:function(){return [_c(VAlert,{staticClass:"ma-0 pt-4 text-center",attrs:{"value":!!_vm.showGenericError,"border":"top","colored-border":"","dense":"","tile":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.isBoolean(_vm.showGenericError) ? _vm.$t('Common.Error.Generic') : _vm.showGenericError)+" ")]),_c(VDivider),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"disabled":_vm.isFormSaving,"large":"","plain":"","tile":""},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.isReadOnly ? _vm.$t('Common.Button.Close') : _vm.$t('Common.Button.Cancel'))+" ")]),(!_vm.isReadOnly)?_c(VBtn,{attrs:{"disabled":_vm.isFormSaving,"loading":_vm.isFormSaving,"color":"success","depressed":""},domProps:{"textContent":_vm._s(_vm.$t('Common.Button.Save'))},on:{"click":_vm.saveFromData}}):_vm._e()],1)]},proxy:true}],null,false,922191836)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
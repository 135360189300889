import BaseModel      from '@/lib/data/model/BaseModel'
import formDataCommon from '@/mixins/form/formDataCommon'

export default {
  name     : 'DialogData',
  dataModel: BaseModel,
  data () {
    return {
      parentComponentName: this.$parent.$options.name
    }
  },
  mixins: [formDataCommon],

  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.initModel()
      } else {
        this.resetFormValidation()
        if (this.$refs.formContainer) this.$refs.formContainer.scrollTop = 0
      }
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
    }
  },

  methods: {},

  computed: {
    isReadOnly () {
      return !this.userCan(this.parentComponentName, this.PermissionEnum.MANAGE) || this.itemViewMode
    }
  }
}

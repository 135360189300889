<template>
  <data-card v-bind="$props">
    <template #toolbar-append>
      <slot name="toolbar-append" />
    </template>

    <template #default>
      <v-expand-transition>
        <v-card-text
          v-show="parseInt(listHeight) > 0"
          :class="cardTextClass"
        >
          <slot name="list">
            <v-list
              :color="listColor"
              :dense="listDense"
              :height="listHeight"
              class="overflow-y-auto"
              two-line
            >
              <template v-for="(item, index) in data">
                <slot
                  :index="index"
                  :item="item"
                >
                  <v-list-item
                    :key="`item-${index}`"
                    :class="listItemClass"
                    :color="listItemColor"
                    :to="constructRouteObject (item, to)"
                    class="px-1"
                    @click="handleClick(item)"
                  >
                    <slot
                      :index="index"
                      :item="item"
                      name="list-item-content-prepend"
                    >
                      <v-list-item-avatar
                        v-if="avatar"
                        :color="avatarColor ? avatarColor : `${color} lighten-4`"
                      >
                        <v-icon :color="avatarIconColor ? avatarIconColor : color">
                          {{ avatarIcon ? avatarIcon : icon }}
                        </v-icon>
                      </v-list-item-avatar>
                    </slot>

                    <slot
                      :color="color"
                      :index="index"
                      :item="item"
                      name="list-item-content"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-if="item.headline"
                          :class="`${color}--text`"
                          v-text="item.headline"
                        />
                        <v-list-item-subtitle
                          v-if="item.title"
                          class="text--primary"
                          v-text="item.title"
                        />
                        <v-list-item-subtitle
                          v-if="item.subTitle"
                          v-text="item.subTitle"
                        />
                      </v-list-item-content>
                    </slot>

                    <slot
                      :index="index"
                      :item="item"
                      name="list-item-content-append"
                    >
                      <v-list-item-action v-if="item.action">
                        <v-list-item-action-text v-text="item.action" />
                      </v-list-item-action>
                    </slot>
                  </v-list-item>
                </slot>

                <v-divider
                  v-if="index + 1 < data.length"
                  :key="`divider-${index}`"
                />
              </template>
            </v-list>
          </slot>
        </v-card-text>
      </v-expand-transition>
    </template>

    <template #card-actions>
      <slot name="card-actions" />
    </template>
  </data-card>
</template>

<script>
import DataCard from '../common/DataCard'

export default {
  name      : 'ListCard',
  components: { DataCard },
  directives: {},
  mixins    : [],
  props     : {
    ...DataCard.props,

    data: {
      type    : Array,
      default : () => [],
      required: false
    },
    to: {
      type   : [String, Object],
      default: ''
    },
    listDense: {
      type: Boolean
    },
    avatar: {
      type: Boolean
    },
    avatarIcon: {
      type   : String,
      default: ''
    },
    avatarIconColor: {
      type   : String,
      default: ''
    },
    avatarColor: {
      type   : String,
      default: ''
    },
    listColor: {
      type   : String,
      default: ''
    },
    listItemColor: {
      type   : String,
      default: ''
    },
    listItemClass: {
      type   : [String, Array],
      default: ''
    },
    listHeight: {
      type   : [String, Number],
      default: undefined
    },
    cardTextClass: {
      type   : [String, Array],
      default: ''
    },
    clickEvent: {
      type   : String,
      default: 'click'
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    handleClick (item) {
      this.$emit(this.clickEvent, item)
    },

    constructRouteObject (item, to) {
      let retVal = ''
      const obj = {}
      let paramsArr = []
      if (item && to && to.hasOwnProperty('name') && to.name) {
        paramsArr = to.hasOwnProperty('params') && Array.isArray(to.params) ? to.params : []
        paramsArr.forEach(key => { obj[key] = item[key] })
        retVal = {
          name  : to.name,
          params: obj
        }
      }
      return retVal
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-footer
    app
    clipped-left
    dark
    fixed
    padless
  >
    <v-row class="align-center justify-space-between">
      <v-col
        :style="`min-width: ${$vuetify.breakpoint.xsOnly ? 68 : 75}px;`"
        cols="auto"
        @click="showVersion = !showVersion"
      >
        <v-slide-x-transition>
          <span
            v-show="showVersion"
            class="caption ml-2 grey--text text--darken-2"
          >v{{ $dataStore.App.Version }}</span>
        </v-slide-x-transition>
      </v-col>

      <v-col cols="auto">
        <div class="pa-1">
          {{ new Date().getFullYear() }} &copy;
          <span class="pl-1">{{ $t('Common.App.Title') }}</span>
        </div>
      </v-col>

      <v-col cols="auto">
        <v-btn
          v-if="$route.meta && $route.meta.layout && $route.meta.layout.toLowerCase() !== 'login'"
          class="mr-2"
          depressed
          fab
          x-small
          @click="toggleThemeDark"
        >
          <v-icon>mdi mdi-invert-colors</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>

export default {
  name      : 'AppFooter',
  components: {},
  directives: {},
  mixins    : [],
  props     : {},
  data () {
    return {
      showVersion: true
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {
    this.$vuetify.theme.dark = this.$localStorage.get('dark', this.$vuetify.theme.dark)
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    toggleThemeDark () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.$localStorage.set('dark', this.$vuetify.theme.dark)
    }
  }
}
</script>

<style scoped>

</style>

export default {
  name    : 'Env',
  computed: {
    isStaging () {
      return process.env.VUE_APP_ENV === 'staging'
    },
    isDevelopment () {
      return process.env.VUE_APP_ENV === 'development'
    },
    isProduction () {
      return process.env.VUE_APP_ENV === 'production'
    }
  }
}

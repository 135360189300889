export default {

  Title: 'Χρήστες',

  Table: {
    Button: {
      New: 'Νέος Χρήστης'
    },

    Headers: {
      Id         : '#',
      FirstName  : 'ΟΝΟΜΑ',
      LastName   : 'ΕΠΩΝΥΜΟ',
      Username   : 'USERNAME',
      Email      : 'EMAIL',
      Role       : 'ΟΜΑΔΑ',
      SingleLogin: 'ΠΑΡΑΛΛΗΛΕΣ ΣΥΝΔΕΣΕΙΣ',
      Active     : 'ΕΝΕΡΓΟΣ',
      CreatedAt  : 'ΕΓΓΡΑΦΗ',
      Actions    : ''
    },

    Filter: {
      Active: {
        Type       : 'v-btn-toggle',
        Label      : 'Χρήστες',
        PlaceHolder: 'Όλοι οι Χρήστες',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: 'null',
            Name : 'Όλοι'
          },
          {
            Value: 1,
            Name : 'Ενεργοί'
          },
          {
            Value: 0,
            Name : 'Ανενεργοί'
          }
        ]
      },

      SingleLogin: {
        Type       : 'v-btn-toggle',
        Label      : 'Παράλληλες Συνδέσεις',
        PlaceHolder: 'Όλοι οι Χρήστες',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: 'null',
            Name : 'Όλες'
          },
          {
            Value: 0,
            Name : 'Ναι'
          },
          {
            Value: 1,
            Name : 'Όχι'
          }
        ]
      },

      RoleId: {
        Type       : 'v-select',
        Label      : 'Ρόλοι',
        PlaceHolder: 'Όλοι οι Ρόλοι',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      },

      CompanyId: {
        Type       : 'v-select',
        Label      : 'Εταιρείες',
        PlaceHolder: 'Όλες οι Εταιρείες',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      }
    }
  },

  Confirm: {
    Delete: {
      Title   : 'Διαγραφή Χρήστη {Id}',
      SubTitle: 'Επιβεβαίωση Διαγραφής Χρήστη',
      Body    : 'Είστε σίγουροι ότι θέλετε προχωρήσετε στην οριστική διαγραφή του χρήστη {Id};'
    }
  },

  Form: {
    Title : 'Χρήστη',
    Fields: {
      Active: {
        Label: 'Χρήστης',
        Hint : ''
      },
      SingleLogin: {
        Label: 'Παράλληλες Συνδέσεις',
        Hint : ''
      },
      TwoFA: {
        Label: '2FA',
        Hint : ''
      },
      ForceChangePassword: {
        Label: 'Αλλαγή κωδικού πρόσβασης στην επόμενη σύνδεση',
        Hint : ''
      },
      Email: {
        Label: 'Email',
        Hint : ''
      },
      FirstName: {
        Label: 'Όνομα',
        Hint : ''
      },
      LastName: {
        Label: 'Επώνυμο',
        Hint : ''
      },
      Username: {
        Label: 'Username',
        Hint : ''
      },
      Password: {
        Label: 'Κωδικός',
        Hint : ''
      },
      RoleId: {
        Label: 'Ρόλος',
        Hint : ''
      },
      CompanyId: {
        Label: 'Εταιρεία',
        Hint : ''
      },
      Locale: {
        Label: 'Γλώσσα',
        Hint : ''
      },
      Phone: {
        Label: 'Τηλέφωνο',
        Hint : ''
      },
      Notes: {
        Label: 'Σημειώσεις',
        Hint : ''
      }
    }
  },

  Login: {
    Title          : 'ΣΥΝΔΕΣΗ ΛΟΓΑΡΙΑΣΜΟΥ',
    Email          : 'EMAIL ',
    Username       : 'USERNAME',
    Password       : 'ΚΩΔΙΚΟΣ',
    NewPassword    : 'ΝΕΟΣ ΚΩΔΙΚΟΣ',
    CurrentPassword: 'ΠΑΛΙΟΣ ΚΩΔΙΚΟΣ',
    ConfirmPassword: 'ΕΠΙΒΕΒΑΙΩΣΗ ΚΩΔΙΚΟΥ',
    Error          : {
      Auth: 'Λάθος όνομα ή κωδικός χρήστη'
    }
  },

  TwoFA: {
    Title   : 'Πιστοποίηση χρήστη δύο παραγόντων',
    SubTitle: 'Για να κάνετε είσοδο συμπληρώστε το PIN που λάβατε στο email που έχετε δηλώσει',
    Pin     : 'PIN'
  },

  ForgotPassword: {
    Title   : 'ΑΝΑΚΤΗΣΗ ΚΩΔΙΚΟΥ ΠΡΟΣΒΑΣΗΣ',
    SubTitle: 'Συμπληρώστε το email σας και θα λάβετε οδηγίες ανάκτησης του κωδικού πρόσβασης'
  },

  ResetPassword: {
    Title          : 'ΕΠΑΝΑΦΟΡΑ ΚΩΔΙΚΟΥ ΠΡΟΣΒΑΣΗΣ',
    SubTitle       : 'Για να επαναφέρεις τον κωδικό πρόσβασης του λογαρισμού σου, συμπλήρωσε και επιβεβαίωσε τον νέο κωδικό πρόσβασης',
    NewPassword    : 'ΝΕΟΣ ΚΩΔΙΚΟΣ',
    ConfirmPassword: 'ΕΠΙΒΕΒΑΙΩΣΗ ΚΩΔΙΚΟΥ'
  },

  ChangePassword: {
    Title          : 'ΑΛΛΑΓΗ ΚΩΔΙΚΟΥ ΠΡΟΣΒΑΣΗΣ',
    SubTitle       : 'Για να αλλάξεις τον κωδικό πρόσβασης του λογαρισμού σου, συμπλήρωσε τον παλιό και τον νέο κωδικό πρόσβασης',
    CurrentPassword: 'ΠΑΛΙΟΣ ΚΩΔΙΚΟΣ',
    NewPassword    : 'ΝΕΟΣ ΚΩΔΙΚΟΣ',
    ConfirmPassword: 'ΕΠΙΒΕΒΑΙΩΣΗ ΚΩΔΙΚΟΥ'
  },

  Logout: {
    Title: 'Αποσύνδεση'
  }

}

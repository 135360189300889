import API from '@/api/Api'

export default {
  name: 'DataTableActions',
  data () {
    return {
      itemViewMode     : false,
      selectedItem     : null,
      editDialogVisible: false
    }
  },
  methods: {
    createItem (dialogVisibleProp = 'editDialogVisible') {
      this.itemViewMode = false
      this.selectedItem = null
      this[dialogVisibleProp] = true
    },

    viewItem (item) {
      this.itemViewMode = true
      this.$set(item, 'loadingView', true)
      this.getItem(item).finally(() => {
        this.$set(item, 'loadingView', false)
      })
    },

    editItem (item) {
      this.itemViewMode = false
      this.$set(item, 'LoadingEdit', true)
      this.getItem(item).finally(() => {
        this.$set(item, 'LoadingEdit', false)
      })
    },

    deleteItem (item, langBaseKey, optionsObj = {}) {
      if (typeof item.Delete !== 'function') return
      if (!langBaseKey) langBaseKey = this.$options.name || 'Common'
      const mainLangBaseKey = this.$te(`${langBaseKey}.Confirm.Delete`) ? this.$options.name : 'Common'
      const validateLangBaseKey = this.$te(`${langBaseKey}.Confirm.Delete.Validate`) ? this.$options.name : 'Common'

      const defaultOptions = {
        color   : 'error',
        icon    : 'mdi-alert',
        validate: this.$te(`${validateLangBaseKey}.Confirm.Delete.Validate`) ? {
          text  : this.$t(`${validateLangBaseKey}.Confirm.Delete.Validate.Text`),
          answer: this.$t(`${validateLangBaseKey}.Confirm.Delete.Validate.Answer`)
        } : false,
        coloredBorder: false,
        dark         : true,
        button       : {
          no : { title: this.$t('Common.Button.No') },
          yes: { title: this.$t('Common.Button.Yes') }
        }
      }
      optionsObj = { ...defaultOptions, ...optionsObj }

      this.itemViewMode = false

      this.$root.$confirm(
        this.$t(`${mainLangBaseKey}.Confirm.Delete.Title`, { Id: `#${item.Id}` }),
        this.$t(`${mainLangBaseKey}.Confirm.Delete.SubTitle`, { Id: `#${item.Id}` }),
        this.$t(`${mainLangBaseKey}.Confirm.Delete.Body`, { Id: `#${item.Id}` }),
        optionsObj
      ).then(response => {
        if (response) {
          this.$set(item, 'LoadingDelete', true)
          item.Delete()
            .then(response => {
              if (response.status === 200 && response.data?.code === 200 && response.data?.success) {
                this.getData()
              }
            })
            .catch(e => {
            })
            .finally(() => {
              this.$set(item, 'LoadingDelete', false)
            })
        }
      })
    },

    getItem (item, dialogVisibleProp = 'editDialogVisible') {
      if (typeof item.Get !== 'function') return

      return item.Get()
        .then(response => {
          if (API.isResponseSuccess(response)) {
            this.selectedItem = response.data.data
            this[dialogVisibleProp] = true
          }
        })
        .catch(e => {
        })
    }
  }

}

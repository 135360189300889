import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"fill-height"},[_c(VCardTitle,{staticClass:"pa-2 d-block"},[_c(VToolbar,{attrs:{"color":!_vm.$vuetify.theme.dark ? 'grey lighten-3' : '',"extension-height":"60","flat":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.userCanAdd)?_c(VBtn,{staticClass:"px-2 mr-1",attrs:{"fab":_vm.$vuetify.breakpoint.mobile,"small":_vm.$vuetify.breakpoint.mobile,"color":"primary"},on:{"click":function($event){return _vm.createItem()}}},[_c(VIcon,{class:{'mr-1': !_vm.$vuetify.breakpoint.mobile}},[_vm._v(" mdi-plus ")]),(!_vm.$vuetify.breakpoint.mobile)?[_vm._v(" "+_vm._s(_vm.$t(((_vm.$options.name) + ".Table.Button.New")).toLocaleUpperCase(_vm.$i18n.locale))+" ")]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"flex-grow-1"}),(_vm.dataTable.search.enabled)?_c('data-table-search',{model:{value:(_vm.dataTable.search.terms),callback:function ($$v) {_vm.$set(_vm.dataTable.search, "terms", $$v)},expression:"dataTable.search.terms"}}):_vm._e(),_c('data-table-filter',{attrs:{"filters":_vm.filterData},model:{value:(_vm.dataTable),callback:function ($$v) {_vm.dataTable=$$v},expression:"dataTable"}})]},proxy:true}])})],1),_c(VDataTable,{attrs:{"footer-props":{itemsPerPageOptions: [5,10,15,20,25]},"headers":_vm.dataTable.headers,"height":_vm.dataTable.resize.bodyHeight,"items":_vm.dataTable.data,"loading":_vm.dataTable.loading,"options":_vm.dataTable.options,"search":_vm.dataTable.search.terms,"server-items-length":_vm.dataTable.total,"fixed-header":"","item-key":"Id"},on:{"update:options":function($event){return _vm.$set(_vm.dataTable, "options", $event)}},scopedSlots:_vm._u([{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.Name)+" ")]),_c('div',{staticClass:"caption grey--text"},[_vm._v(" "+_vm._s(item.Description)+" ")])]}},{key:"item.Active",fn:function(ref){
var item = ref.item;
return [_c(VSwitch,{staticClass:"ma-0 pt-0 d-inline-flex",attrs:{"disabled":!_vm.userCanManage,"readonly":!_vm.userCanManage,"color":"success","hide-details":"","inset":""},on:{"change":function($event){return item.Update()}},model:{value:(item.Active),callback:function ($$v) {_vm.$set(item, "Active", $$v)},expression:"item.Active"}})]}},(_vm.userCanDelete || _vm.userCanManage || _vm.userCanAccess)?{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c(VSpeedDial,{attrs:{"direction":"left","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VBtn,{attrs:{"color":"blue darken-2","dark":"","depressed":"","fab":"","x-small":""},model:{value:(item.fab),callback:function ($$v) {_vm.$set(item, "fab", $$v)},expression:"item.fab"}},[(item.fab)?_c(VIcon,[_vm._v(" mdi-close ")]):_c(VIcon,[_vm._v(" mdi-menu ")])],1)]},proxy:true}],null,true),model:{value:(item.fab),callback:function ($$v) {_vm.$set(item, "fab", $$v)},expression:"item.fab"}},[(_vm.userCanDelete)?_c(VTooltip,{attrs:{"open-delay":650,"bottom":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.userCanDelete)?_c(VBtn,_vm._g({staticClass:"ml-1 white--text",attrs:{"disabled":item.loadingDelete,"loading":item.loadingDelete,"color":"red","depressed":"","fab":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}},nativeOn:{"click":function($event){item.fab = false}}},on),[_c(VIcon,[_vm._v("mdi-close")])],1):_vm._e()]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('Common.Button.Delete'))}})]):_vm._e(),(_vm.userCanAccess)?_c(VTooltip,{attrs:{"open-delay":650,"bottom":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.userCanAccess)?_c(VBtn,_vm._g({staticClass:"ml-1 white--text",attrs:{"disabled":item.loadingView,"loading":item.loadingView,"color":"info","depressed":"","fab":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.viewItem(item)}},nativeOn:{"click":function($event){item.fab = false}}},on),[_c(VIcon,[_vm._v("mdi-eye")])],1):_vm._e()]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('Common.Button.View'))}})]):_vm._e(),(_vm.userCanManage)?_c(VTooltip,{attrs:{"open-delay":650,"bottom":"","color":"grey"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.userCanManage)?_c(VBtn,_vm._g({staticClass:"ml-1 white--text",attrs:{"disabled":item.loadingEdit,"loading":item.loadingEdit,"color":"grey","depressed":"","fab":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}},nativeOn:{"click":function($event){item.fab = false}}},on),[_c(VIcon,[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('Common.Button.Edit'))}})]):_vm._e()],1)]}}:null],null,true)})],1)],1)],1),(_vm.userCanAccess || _vm.userCanAdd || _vm.userCanManage)?_c('company-edit-dialog',{attrs:{"item":_vm.selectedItem,"item-view-mode":_vm.itemViewMode,"visible":_vm.editDialogVisible},on:{"update:visible":function($event){_vm.editDialogVisible=$event},"form:save:success":_vm.getData}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { AES, HmacMD5 } from '@/lib/crypto/crypto'

const storageKey = HmacMD5.hash('ROUTE')

export default (to, from, next, injection) => {
  const vue = injection

  if (vue && to.name !== 'Login' && to.name !== 'ForgotPassword' && to.name !== 'ResetPassword' && to.name !== 'ChangePassword') {
    vue.$sessionStorage.set(storageKey, AES.encrypt({
      name    : to.name,
      fullPath: to.fullPath,
      hash    : to.hash,
      meta    : to.meta,
      params  : to.params,
      path    : to.path,
      query   : to.query
    }))
  }
}

import BaseResource       from '@/lib/data/resource/BaseResource'
import CastValueEnum      from '@/api/enums/CastValueEnum'
import ValidatorTypesEnum from '@/lib/data/validator/ValidatorTypesEnum'

export default class SettingItemResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.definePrivateProperty('Cast', 'string')

    this.Key = payload.key ?? ''
    this.Value = payload.value ?? ''
    this.Active = payload.active ?? false
    this.Readonly = payload.readonly ?? false
    this.Visible = payload.visible ?? false
    this.Cast = payload.cast ?? 'string'
  }

  /* PROPERTIES */
  get Cast () {
    return this._Cast
  }

  set Cast (val) {
    this._Cast = val ?? 'string'

    if (!this.validator) return

    switch (val) {
    case CastValueEnum.INT.Value:
      this.validator.validatorRules.Value.type = ValidatorTypesEnum.Numeric
      break

    case CastValueEnum.DECIMAL.Value:
    case CastValueEnum.FLOAT.Value:
    case CastValueEnum.DOUBLE.Value:
    case CastValueEnum.REAL.Value:
      this.validator.validatorRules.Value.type = ValidatorTypesEnum.Float
      break

    case CastValueEnum.BOOL.Value:
      this.validator.validatorRules.Value.type = ValidatorTypesEnum.Boolean
      break

    default:
      this.validator.validatorRules.Value.type = ValidatorTypesEnum.String
    }
  }

  /* METHODS */
}

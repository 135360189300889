const CastValueEnum = {
  INT: {
    Name  : 'Integer',
    Value : 'int',
    Active: true
  },
  REAL: {
    Name  : 'Real',
    Value : 'real',
    Active: true
  },
  FLOAT: {
    Name  : 'Float',
    Value : 'float',
    Active: true
  },
  DOUBLE: {
    Name  : 'Double',
    Value : 'double',
    Active: true
  },
  DECIMAL: {
    Name  : 'Decimal',
    Value : 'decimal',
    Active: true
  },
  STRING: {
    Name  : 'String',
    Value : 'string',
    Active: true
  },
  BOOL: {
    Name  : 'Boolean',
    Value : 'bool',
    Active: true
  },
  OBJECT: {
    Name  : 'Object',
    Value : 'object',
    Active: false
  },
  ARRAY: {
    Name  : 'Array',
    Value : 'array',
    Active: false
  },
  JSON: {
    Name  : 'Json',
    Value : 'json',
    Active: false
  },
  COLLECTION: {
    Name  : 'Collection',
    Value : 'collection',
    Active: false
  },
  DATE: {
    Name  : 'date',
    Value : 'date',
    Active: true
  },
  DATETIME: {
    Name  : 'datetime',
    Value : 'datetime',
    Active: true
  },
  TIMESTAMP: {
    Name  : 'timestamp',
    Value : 'timestamp',
    Active: true
  }
}

export default CastValueEnum

import Vue                       from 'vue'
import Storage                   from 'vue-web-storage'
import PortalVue                 from 'portal-vue'
import VueMeta                   from 'vue-meta'
import VueMask                   from 'v-mask'
import App                       from '@/App'
import VueFilters                from '@/filters/index'
import vuetify                   from '@/plugins/vuetify'
import { resetRouter, router }   from '@/router'
import { i18n, setI18nLanguage } from '@/lang/lang'
import DataStorePlugin           from '@/lib/data/dataStore/plugin/DataStorePlugin'
import UtilsPlugin               from '@/lib/utils/plugin'
import EventBus                  from '@/lib/events/eventBus'
import Enums                     from '@/lib/enums/plugin'
import { themeUtils }            from '@/lib/utils'
import DayjsPlugin               from '@/lib/date/plugin'
import EnvMixin                  from '@/mixins/env'
import AuthMixin                 from '@/mixins/auth'
import LangMiddleware            from '@/router/middleware/LangMiddleware'
import StoreRouteMiddleware      from '@/router/middleware/StoreRouteMiddleware'
import RouteMiddleware           from '@/lib/middleware/RouteMiddleware'
import AuthMiddleware            from '@/router/middleware/AuthMiddleware'
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import './App.css'
import RouterReadyMiddleware     from '@/router/middleware/RouterReadyMiddleware'
import { setupBugsnag }          from '@/lib/services/Bugsnag'
import API                       from '@/api/Api'
import DataStore                 from '@/lib/data/dataStore/DataStore'

require('@openfonts/roboto_greek')

const LoginLayout = () => themeUtils.importThemeComponent('layouts/LoginLayout')
// const LoginLayout = import(/* webpackChunkName: "LoginLayout" */ '@/theme/default/layouts/LoginLayout')
const DefaultLayout = () => themeUtils.importThemeComponent('layouts/DefaultLayout')
// const DefaultLayout = import(/* webpackChunkName: "DefaultLayout" */ '@/theme/default/layouts/DefaultLayout')

const getSystemConfig = () => {
  API.Resource.Config.Get()
    .then(response => {
      API.isResponseSuccess(response) && init(response.data)
    })
    .catch(e => {})
    .finally(() => { })
}

const initVue = () => {
  resetRouter()

  Vue.component('DefaultLayout', DefaultLayout)
  Vue.component('LoginLayout', LoginLayout)

  Vue.mixin(EnvMixin)
  Vue.mixin(AuthMixin)

  Vue.use(VueFilters)
  Vue.use(EventBus)
  Vue.use(DataStorePlugin)
  Vue.use(UtilsPlugin)
  Vue.use(DayjsPlugin)
  Vue.use(Enums)
  Vue.use(PortalVue)
  Vue.use(VueMask)
  Vue.use(Storage, {
    prefix : `${ process.env.VUE_APP_STORAGE_PREFIX }_`,
    drivers: ['session', 'local']
  })
  Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
  })

  window.Vue = new Vue({
    el        : '#app',
    directives: {},
    mixins    : [],
    data () {
      return {}
    },
    beforeMount () {
      setI18nLanguage(process.env.VUE_APP_I18N_LOCALE)
      this.$router.onReady(RouterReadyMiddleware)
      router.beforeEach(RouteMiddleware({
        LangMiddleware,
        AuthMiddleware
      }, true, this))
      router.afterEach(RouteMiddleware({ StoreRouteMiddleware }, true, this))
    },
    beforeCreate () {},
    created () {},
    mounted () {
      this.$bus.$on('app:logout', this.logout)
    },
    beforeDestroy () {
      this.$bus.$off('app:logout', this.logout)
    },
    methods: {},
    i18n,
    router,
    vuetify,
    render : h => h(App)
  })

  if (process.env.NODE_ENV === 'production') {
    Vue.config.productionTip = false
    Vue.config.devtools = false
    Vue.config.debug = false
    Vue.config.silent = true
  }
}

const init = (data = {}) => {
  DataStore.App.Config = { ...DataStore?.App?.Config ?? {}, ...data?.data ?? {} }

  initVue()
  setupBugsnag()
}

getSystemConfig()

import BaseModel        from '@/lib/data/model/BaseModel'
import CompanyValidator from '@/api/validations/company/CompanyValidator'
import CompanyResource  from '@/api/resources/company/CompanyResource'
import API              from '@/api/Api'

export default class CompanyModel extends BaseModel {
  constructor (payload = {}, resource = CompanyResource, validator = CompanyValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */

  async Get () {
    return await API.Resource.Company.Get(this.Id)
  }

  async Create () {
    return await API.Resource.Company.Create(this.clone())
  }

  async Update () {
    return await API.Resource.Company.Update(this.Id, this.clone())
  }

  async UpdateOrCreate () {
    return await API.Resource.Company.UpdateOrCreate(this.clone())
  }

  async Delete () {
    return await API.Resource.Company.Delete(this.Id)
  }
}

import { render, staticRenderFns } from "./TwoFALogin.vue?vue&type=template&id=1b720e6f&scoped=true&"
import script from "./TwoFALogin.vue?vue&type=script&lang=js&"
export * from "./TwoFALogin.vue?vue&type=script&lang=js&"
import style0 from "./TwoFALogin.vue?vue&type=style&index=0&id=1b720e6f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b720e6f",
  null
  
)

export default component.exports
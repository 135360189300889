import axios            from 'axios'
import { AES, HmacMD5 } from '@/lib/crypto/crypto'
import ApiResponseEnum  from '@/api/enums/ApiResponseEnum'
import { i18n }         from '@/lang/lang'

const authorizationTokenKey = HmacMD5.hash('authorizationToken')
const errorCodes = [ApiResponseEnum.FORBIDDEN, ApiResponseEnum.UNAUTHORIZED]

export const Http = {
  /**
   * Axios Client
   */
  Client: axios.create({
    baseURL        : `${ process.env.VUE_APP_API_BASE_URL }${ process.env.VUE_APP_API_PREFIX ? process.env.VUE_APP_API_PREFIX : '' }` || '',
    timeout        : parseInt(process.env.VUE_APP_API_TIMEOUT) || 30000,
    responseType   : 'json',
    crossDomain    : true,
    withCredentials: true,
    headers        : {
      'X-Requested-With': 'XMLHttpRequest'
    }
  }),

  /**
   * Request
   *
   * @param Endpoint
   * @param Data
   * @param Config
   *
   * @returns {Promise<void>}
   * @constructor
   */
  async Request (Endpoint, Data = {}, Config = {}) {
    if (!Endpoint.Method) Endpoint.Method = Http.Method.Post

    switch (Endpoint.Method) {
    case Http.Method.Get:
    case Http.Method.Delete:
      return await Http.Client[Endpoint.Method](Endpoint.Path, { params: { ...Data }, ...Config })
    case Http.Method.Post:
    case Http.Method.Put:
    case Http.Method.Patch:
      return await Http.Client[Endpoint.Method](Endpoint.Path, Data, { params: {}, ...Config })
    }
  },

  /**
   * RequestAll
   *
   * @param Requests {Array<Request>}
   * @returns A new Promise. {Promise<unknown[]>}
   * @constructor
   */
  async RequestAll (Requests) {
    return await Promise.all(Requests)
  },

  /**
   * Method
   *
   * @returns {{Delete: string, Post: string, Get: string, Patch: string, Put: string}}
   * @constructor
   */
  get Method () {
    return {
      Get   : 'get',
      Post  : 'post',
      Put   : 'put',
      Patch : 'patch',
      Delete: 'delete'
    }
  }
}

Http.Client.interceptors.request.use((requestConfig) => {
  const token = window.Vue && `${ AES.decrypt(window.Vue.$sessionStorage.get(authorizationTokenKey, null)) }` || null

  const defaultRequestParams = {}
  const defaultRequestHeaders = {
    'Accept-Language': i18n.code,
    'X-Referer'      : process.env.VUE_APP_API_X_REFERER || '',
    Authorization    : token ? `Bearer ${ token }` : ''
  }

  requestConfig.headers = Object.assign({}, defaultRequestHeaders || {}, requestConfig.headers || {})
  requestConfig.params = Object.assign({}, defaultRequestParams || {}, requestConfig.params || {})
  return requestConfig
}, function (error) {
  return Promise.reject(error)
})

Http.Client.interceptors.response.use((response) => {
  return response
}, function (error) {
  if (error && (error.message === 'Network Error' || errorCodes.includes(error?.response?.status))) {
    window.Vue.user = null
    if (window.Vue.$router.currentRoute.name !== 'Login') {
      window.Vue.$router.push({
        name : 'Login',
        query: {}
      })
    }
  }
  return Promise.reject(error)
})

export default Http

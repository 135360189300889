import i18n           from '@/lang/lang'
import PermissionEnum from '@/api/enums/PermissionEnum'

export default async (to, from, next, injection) => {
  const vue = injection

  if (to.meta.requiresAuth) {
    if (!vue.user) {
      next({
        replace: true,
        name   : 'Login',
        params : { lang: i18n.locale },
        query  : {}
      })
    } else {
      next(vue.userHasComponentPermission(to.name, PermissionEnum.ACCESS))
    }
  } else {
    next()
  }
}
